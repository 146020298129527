import React, { FC } from "react";
import plane from "../../assets/icons/planeP.svg";
import star from "../../assets/icons/star.svg";
import food from "../../assets/icons/food.svg";
import children from "../../assets/icons/children.svg";
import bus from "../../assets/icons/bus.svg";
import beach from "../../assets/icons/beach.svg";
import google from "../../assets/icons/Google.png";
import trip from "../../assets/icons/Tripadvisor.png";

import { ResultsTypes } from "../../types/pakkumisedTypes/offers";
import { Link } from "react-router-dom";

type BoardType = "AI" | "BB" | "RO" | "HB" | "UAI";
const boardMeanings: Record<BoardType, string> = {
  AI: "Kõik hinnas",
  BB: "Hommikusöögiga",
  RO: "Ainult tuba",
  HB: "Hommiku- ja õhtusöögiga",
  UAI: "Ultra kõik hinnas",
};
const ResultsCard: FC<ResultsTypes> = ({
  hotelName,
  location,
  checkinDate,
  price,
  stars,
  thumbId,
  nights,
  hotelCode,
  searchParams,
  board,
  ta_rating,
  google_rating,
}): JSX.Element => {
  const formattedCheckinDate = checkinDate.replace(/-/g, ".");
  const [day, month, year] = checkinDate.split("-").map(Number);
  const checkinDateObj = new Date(year, month - 1, day);
  const checkoutDate = new Date(
    checkinDateObj.getTime() + nights * 24 * 60 * 60 * 1000
  );
  const formattedCheckoutDate = checkoutDate.toLocaleDateString("et-EE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const imageUrl = `https://reisihunt.ee/thumbs/${thumbId}.jpg`;
  const renderStars = (stars: number) => {
    const starIcons = [];
    for (let i = 0; i < stars; i++) {
      starIcons.push(<img key={i} src={star} alt={`Star ${i + 1}`} />);
    }
    return starIcons;
  };
  const boardMeaning = (board && boardMeanings[board as BoardType]) || "";
  const showBoardMeaning = board !== "NA";
  return (
    <div className="results-card">
      <Link
        to={{
          pathname: `/results/${hotelCode}`,
          search: searchParams,
        }}
      >
        <div className="results-card__image">
          <img src={imageUrl} alt="" />
        </div>
        <div className="results-card__info">
          <div className="results-card__info--top">
            <div className="results-card__left">
              <h2>{hotelName}</h2>
              <p>{location as any}</p>
              {renderStars(stars)}
              <span></span>
              <div
                className="rating"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1.25rem",
                }}
              >
                {ta_rating && (
                  <p
                    style={{
                      fontSize: "1rem",
                      color: "#6750A1",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.25rem",
                    }}
                  >
                    <img src={trip} alt="" />
                    {ta_rating}
                  </p>
                )}
                {google_rating && (
                  <p
                    style={{
                      fontSize: "1rem",
                      color: "#6750A1",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.25rem",
                    }}
                  >
                    <img src={google} alt="" />
                    {google_rating}
                  </p>
                )}
              </div>
            </div>
            <div className="results-card__right">
              <span>
                <img src={plane} alt="" />
                Tallinn
              </span>
              <p>
                <small>{formattedCheckinDate} -</small>
                <small>{formattedCheckoutDate}</small>
                <small>&nbsp;({nights} ööd) </small>
              </p>
            </div>
          </div>
          <div className="results-card__info--bottom">
            <div className="results-card__left">
              <ul>
                {showBoardMeaning && (
                  <li>
                    <img src={food} alt="" />
                    {boardMeaning}
                  </li>
                )}
                {/* <li>
                  <img src={children} alt="" />
                  Lastesõbralik{" "}
                </li>
                <li>
                  <img src={bus} alt="" />
                  Lennujaama transfeer
                </li>
                <li>
                  <img src={beach} alt="" />
                  Ranna lähedal
                </li> */}
              </ul>
            </div>
            <div className="results-card__right">
              <p>alates</p>
              <h2>{price} €</h2>
              <p>inimese kohta</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ResultsCard;
