import React, { FC } from "react";
import ResultsFilter from "../components/ResultsComponents/ResultsFilter";
import ResultsView from "../components/ResultsComponents/ResultsView";
import Hero from "../components/LandingPageComponents/Hero";
import { FilterProvider } from "../components/ResultsComponents/ResultsFilterprovider";

const Results: FC = (): JSX.Element => {
  return (
    <div className="results">
      <Hero />

      <div className="results__body">
        <div className="results__layout">
          <FilterProvider>
            <ResultsFilter />
            <ResultsView />
          </FilterProvider>
        </div>
      </div>
    </div>
  );
};

export default Results;
