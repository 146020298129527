import React, { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import et from "date-fns/locale/et";
import { format, parse } from "date-fns";
import minus from "../../../assets/icons/minus.svg";
import plus from "../../../assets/icons/plus.svg";
import arrow from "../../../assets/icons/arrowDpurple.svg";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";

const countries = [
  { value: "all", label: "Kõik sihtkohad" },
  { value: "EG", label: "Egiptus" },
  { value: "HRG", label: "Hurghada" },
  { value: "RMF", label: "Marsa Alam" },
  { value: "SSH", label: "Sharm El Sheikh" },
  { value: "TR", label: "Türgi" },
  { value: "MA", label: "Maroko" },
  { value: "BG", label: "Bulgaaria" },
  { value: "ES", label: "Hispaania" },
  { value: "IT", label: "Itaalia" },
  { value: "ID", label: "Indonesia" },
  { value: "CN", label: "Kanaari saared" },
  { value: "GR", label: "Kreeka" },
  { value: "CU", label: "Kuuba" },
  { value: "CY", label: "Küpros" },
  { value: "MU", label: "Mauritius" },
  { value: "MX", label: "Mehhiko" },
  { value: "ME", label: "Montenegro" },
  { value: "PT", label: "Portugal" },
  { value: "SC", label: "Sei\u0161ellid" },
  { value: "LK", label: "Sri Lanka" },
  { value: "TH", label: "Tai" },
  { value: "TZ", label: "Tansaania" },
  { value: "TN", label: "Tuneesia" },
  { value: "AE", label: "Araabia Ühendemiraadid" },
  { value: "VN", label: "Vietnam" },
];
const start = [{ value: "TLL", label: "Tallinn" }];
const HeroSearch: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isStartUlVisible, setIsStartUlVisible] = useState(false);
  const [isEndUlVisible, setIsEndUlVisible] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isDurationVisible, setIsDurationVisible] = useState(false);
  const [isPersonVisible, setIsPersonVisible] = useState(false);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [selectedStart, setSelectedStart] = useState("Tallinn");
  const [selectedEnd, setSelectedEnd] = useState("Kõik sihtkohad");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(() => {
    const currentDate = new Date();
    const nextWeekDate = new Date(
      currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
    );
    return nextWeekDate;
  });
  const [nightStart, setNightStart] = useState(7);
  const [nightEnd, setNightEnd] = useState(14);
  const [child1, setChild1] = useState(0);
  const [child2, setChild2] = useState(0);
  const [child3, setChild3] = useState(0);

  const decreaseNightStart = (event: any) => {
    event.stopPropagation();

    const newNightStart = nightStart - 1;

    if (newNightStart >= 1 && newNightStart <= nightEnd - 1) {
      setNightStart(newNightStart);
    }
  };

  const increaseNightStart = (event: any) => {
    event.stopPropagation();

    const newNightStart = nightStart + 1;

    if (newNightStart <= nightEnd - 1) {
      setNightStart(newNightStart);
    }
  };

  const decreaseNightEnd = (event: any) => {
    event.stopPropagation();

    const newNightEnd = nightEnd - 1;

    if (newNightEnd >= nightStart + 1) {
      setNightEnd(newNightEnd);
    }
  };

  const increaseNightEnd = (event: any) => {
    event.stopPropagation();

    const newNightEnd = nightEnd + 1;

    setNightEnd(newNightEnd);
  };
  const decreaseAdults = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (adults > 1) {
      setAdults(adults - 1);
    }
  };

  const increaseAdults = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAdults(adults + 1);
  };

  const decreaseChildren = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (children > 0) {
      setChildren(children - 1);
    }
  };

  const increaseChildren = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setChildren(children + 1);
  };

  const increaseChild = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setChild1(child1 + 1);
  };

  const decreaseChild = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (child1 > 0) {
      setChild1(child1 - 1);
    }
  };
  const increaseChild2 = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setChild2(child2 + 1);
  };

  const decreaseChild2 = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (child2 > 0) {
      setChild2(child2 - 1);
    }
  };
  const increaseChild3 = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setChild3(child3 + 1);
  };

  const decreaseChild3 = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (child3 > 0) {
      setChild3(child3 - 1);
    }
  };
  const filterPastDates = (date: any) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const startOfMonth = new Date(currentYear, currentMonth, 1);

    return date >= startOfMonth;
  };

  const handleStartChange = (value: any, label: string) => {
    setSelectedStart(label);
    setIsStartUlVisible(false);
  };

  const handleEndChange = (value: any, label: string) => {
    setSelectedEnd(label);
    setIsStartUlVisible(false);
    setIsEndUlVisible(false);
  };

  const handleToggle = (option: string) => {
    switch (option) {
      case "start":
        setIsStartUlVisible(!isStartUlVisible);
        setIsEndUlVisible(false);
        setIsDurationVisible(false);
        setIsPersonVisible(false);
        setIsCalendarOpen(false);
        break;
      case "end":
        setIsEndUlVisible(!isEndUlVisible);
        setIsStartUlVisible(false);
        setIsDurationVisible(false);
        setIsPersonVisible(false);
        setIsCalendarOpen(false);
        break;
      case "duration":
        setIsDurationVisible(!isDurationVisible);
        setIsStartUlVisible(false);
        setIsEndUlVisible(false);
        setIsPersonVisible(false);
        setIsCalendarOpen(false);
        break;
      case "person":
        setIsPersonVisible(!isPersonVisible);
        setIsStartUlVisible(false);
        setIsEndUlVisible(false);
        setIsDurationVisible(false);
        setIsCalendarOpen(false);
        break;
      case "calendar":
        setIsCalendarOpen(!isCalendarOpen);
        setIsStartUlVisible(false);
        setIsEndUlVisible(false);
        setIsDurationVisible(false);
        setIsPersonVisible(false);
        break;
      default:
        break;
    }
  };

  const handleSearch = () => {
    const selectedStartValue =
      start.find((country) => country.label === selectedStart)?.value || "";
    const selectedEndValue =
      countries.find((country) => country.label === selectedEnd)?.value || "";

    const queryParams = {
      depart: selectedStartValue,
      arrive: selectedEndValue,
      startDate: format(startDate, "dd-MM-yyyy"),
      endDate: format(endDate, "dd-MM-yyyy"),
      nightsMin: nightStart,
      nightsMax: nightEnd,
      adults: adults,
      children: children,
      child1: child1,
      child2: child2,
      child3: child3,
      priceMin: 0,
      priceMax: 10000,
    };

    const urlString = qs.stringify(queryParams);
    const url = `/results?${urlString}`;
    sessionStorage.setItem("searchParams", JSON.stringify(queryParams));
    navigate(url);
  };

  const totalTravelers = children > 0 ? `${adults} + ${children}` : `${adults}`;
  const getLabelFromValue = (value: any) => {
    const country = countries.find((country) => country.value === value);
    const startValue = start.find((city) => city.value === value);

    return country ? country.label : startValue ? startValue.label : value;
  };
  useEffect(() => {
    if (location.pathname === "/results") {
      const searchParams = new URLSearchParams(location.search);

      const depart = getLabelFromValue(searchParams.get("depart") || "Tallinn");
      const arrive = getLabelFromValue(searchParams.get("arrive") || "Kõik");
      const nightsMin = parseInt(searchParams.get("nightsMin") || "7", 10);
      const nightsMax = parseInt(searchParams.get("nightsMax") || "14", 10);
      const adults = parseInt(searchParams.get("adults") || "2", 10);
      const children = parseInt(searchParams.get("children") || "0", 10);
      const child1 = parseInt(searchParams.get("child1") || "0", 10);
      const child2 = parseInt(searchParams.get("child2") || "0", 10);
      const child3 = parseInt(searchParams.get("child3") || "0", 10);
      const startDateParam = searchParams.get("startDate");
      const endDateParam = searchParams.get("endDate");

      const parsedStartDate = startDateParam
        ? parse(startDateParam, "dd-MM-yyyy", new Date())
        : new Date();
      const parsedEndDate = endDateParam
        ? parse(endDateParam, "dd-MM-yyyy", new Date())
        : new Date();
      setSelectedStart(depart);
      setSelectedEnd(arrive);
      setNightStart(nightsMin);
      setNightEnd(nightsMax);
      setAdults(adults);
      setChildren(children);
      setChild1(child1);
      setChild2(child2);
      setChild3(child3);
      setStartDate(parsedStartDate);
      setEndDate(parsedEndDate);
    } else {
      setSelectedStart("Tallinn");
      setSelectedEnd("Kõik sihtkohad");
      setNightStart(7);
      setNightEnd(14);
      setAdults(2);
      setChildren(0);
      setChild1(0);
      setChild2(0);
      setChild3(0);
      setStartDate(new Date());
      setEndDate(endDate);
    }
  }, [location]);
  return (
    <div className="search">
      <div className="search__options">
        <button
          className="search__options--start"
          onClick={() => handleToggle("start")}
        >
          <h5>Lähtekoht:</h5>
          <p>{selectedStart}</p>
          {isStartUlVisible ? (
            <img src={arrow} alt="" style={{ rotate: "180deg" }} />
          ) : (
            <img src={arrow} alt="" />
          )}
          {isStartUlVisible && (
            <ul className="search__options--select">
              {start.map((country) => (
                <li
                  key={country.value}
                  value={country.value}
                  onClick={() =>
                    handleStartChange(country.value, country.label)
                  }
                >
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "left",
                      height: "100%",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {country.label}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </button>

        <button
          className="search__options--end"
          onClick={() => handleToggle("end")}
        >
          <h5>Sihtkoht:</h5>
          <p>{selectedEnd}</p>
          {isEndUlVisible ? (
            <img src={arrow} alt="" style={{ rotate: "180deg" }} />
          ) : (
            <img src={arrow} alt="" />
          )}
          {isEndUlVisible && (
            <ul className="search__options--end-Ul">
              <div className="scrollable">
                {countries.map((country) => (
                  <li
                    key={country.value}
                    value={country.value}
                    onClick={() =>
                      handleEndChange(country.value, country.label)
                    }
                  >
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        width: "100%",
                        textAlign: "left",
                        height: "100%",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {country.label}
                    </button>
                  </li>
                ))}
              </div>
            </ul>
          )}
        </button>
        <div className="search__options--date">
          <h5>Väljumisaeg:</h5>
          <div style={{ display: "flex" }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date as any)}
              filterDate={filterPastDates}
              locale={et}
              dateFormat="dd.MM.yyyy"
              onClickOutside={() => handleToggle("calendar")}
              onFocus={() => handleToggle("calendar")}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date as any)}
              filterDate={filterPastDates}
              locale={et}
              dateFormat="dd.MM.yyyy"
              onClickOutside={() => handleToggle("calendar")}
              onFocus={() => handleToggle("calendar")}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </div>
        <button
          className="search__options--duration"
          onClick={() => handleToggle("duration")}
        >
          <h5>Kestvus:</h5>
          <div className="duration">
            <p>{nightStart}</p>
            &nbsp;
            <span>-</span>
            &nbsp;
            <p>{nightEnd}</p>
            &nbsp;
            <span>ööd</span>
          </div>
          {isDurationVisible && (
            <div className="search__options--duration-modal">
              <div className="duration-start">
                <button
                  onClick={(event) => decreaseNightStart(event)}
                  className="ico"
                >
                  <img src={minus} alt="" />
                </button>
                <p>{nightStart} ööd</p>
                <button
                  onClick={(event) => increaseNightStart(event)}
                  className="ico"
                >
                  <img src={plus} alt="" />
                </button>
              </div>
              <span>kuni</span>
              <div className="duration-start">
                <button
                  onClick={(event) => decreaseNightEnd(event)}
                  className="ico"
                >
                  <img src={minus} alt="" />
                </button>
                <p>{nightEnd} ööd</p>
                <button
                  onClick={(event) => increaseNightEnd(event)}
                  className="ico"
                >
                  <img src={plus} alt="" />
                </button>
              </div>
            </div>
          )}
          <img
            src={arrow}
            alt=""
            style={{ transform: isDurationVisible ? "rotate(180deg)" : "none" }}
          />
        </button>
        <button
          className="search__options--travelers"
          onClick={() => handleToggle("person")}
        >
          <h5>Reisijaid:</h5>
          <div className="duration">
            <p>{totalTravelers} inimest</p>
          </div>
          {isPersonVisible && (
            <div className="search__options--travelers-modal">
              <div className="duration-start">
                <div className="adults" style={{ textAlign: "left" }}>
                  <p>Täiskasvanuid</p>
                  <span>18+ vanuses</span>
                </div>
                <div className="adults__field">
                  <button
                    onClick={(event) => decreaseAdults(event)}
                    className="ico"
                  >
                    <img src={minus} alt="" />
                  </button>
                  <input value={adults} readOnly />
                  <button
                    onClick={(event) => increaseAdults(event)}
                    className="ico"
                  >
                    <img src={plus} alt="" />
                  </button>
                </div>
              </div>
              <div className="duration-start">
                <div className="child" style={{ textAlign: "left" }}>
                  <p>Lapsi</p>
                  <span>0 - 18 vanuses</span>
                </div>
                <div className="child__field">
                  <button onClick={decreaseChildren} className="ico">
                    <img src={minus} alt="" />
                  </button>
                  <input value={children} readOnly />
                  <button onClick={increaseChildren} className="ico">
                    <img src={plus} alt="" />
                  </button>
                </div>
              </div>
              {children >= 1 && (
                <div className="duration-start" style={{ marginLeft: ".5rem" }}>
                  <div className="child">
                    <span>1 lapse vanuse</span>
                  </div>
                  <div className="child__field">
                    <button onClick={decreaseChild} className="ico">
                      <img src={minus} alt="" />
                    </button>
                    <input value={child1} readOnly />
                    <button onClick={increaseChild} className="ico">
                      <img src={plus} alt="" />
                    </button>
                  </div>
                </div>
              )}
              {children >= 2 && (
                <div className="duration-start" style={{ marginLeft: ".5rem" }}>
                  <div className="child">
                    <span>2 lapse vanuse</span>
                  </div>
                  <div className="child__field">
                    <button onClick={decreaseChild2}>
                      <img src={minus} alt="" />
                    </button>
                    <input value={child2} readOnly />
                    <button onClick={increaseChild2}>
                      <img src={plus} alt="" />
                    </button>
                  </div>
                </div>
              )}{" "}
              {children >= 3 && (
                <div className="duration-start" style={{ marginLeft: ".5rem" }}>
                  <div className="child">
                    <span>3 lapse vanuse</span>
                  </div>
                  <div className="child__field">
                    <button onClick={decreaseChild3}>
                      <img src={minus} alt="" />
                    </button>
                    <input value={child3} readOnly />
                    <button onClick={increaseChild3}>
                      <img src={plus} alt="" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          <img
            src={arrow}
            alt=""
            style={{ transform: isPersonVisible ? "rotate(180deg)" : "none" }}
          />
        </button>
        <div className="search__options--button">
          <button className="btn-primary btn" onClick={handleSearch}>
            {location.pathname === "/results" ? "Muuda" : "Otsi"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSearch;
