import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBarSm from "./components/NavBarComponents/NavBarSm";
import routes from "./routes";
import Footer from "./components/FooterComponents/Footer";
import NavBar from "./components/NavBarComponents/NavBar";

function App() {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      {windowWidth < 1024 ? <NavBarSm /> : <NavBar />}
      <div>
        <Routes>
          {routes.map((route) => (
            <Route path={route.path} element={route.element} />
          ))}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
