import { et } from "date-fns/locale";
import React, { FC, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { ReactComponent as UnChecked } from "../../assets/icons/radio.svg";
import { ReactComponent as Checked } from "../../assets/icons/radioCheck.svg";

import "react-calendar/dist/Calendar.css";
import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

interface HotelOffer {
  checkinDate: string;
  price: number;
  room: string[];
  board: string[];
  nights: number;
  id: string;
  operator: string;
}
type BoardType = "AI" | "BB" | "RO" | "HB" | "UAI" | "NA";

const boardTypeMapping: Record<BoardType, string> = {
  AI: "Kõik hinnas",
  BB: "Hommiku- ja õhtusöögiga",
  RO: "Ainult tuba",
  HB: "Hommiku- ja õhtusöögiga",
  UAI: "Ultra kõik hinnas",
  NA: "NA",
};
type LowestPricesByDate = Record<string, number>;

const ResultsBooking: FC = (): JSX.Element => {
  const [board, setBoard] = useState<BoardType>("AI");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [filteredHotelOffers, setFilteredHotelOffers] = useState<HotelOffer[]>(
    []
  );
  const [hotelOffers, setHotelOffers] = useState<HotelOffer[]>([]);
  const [selectedHotelOffer, setSelectedHotelOffer] = useState<string | null>(
    null
  );

  const [lowestPricesByDate, setLowestPricesByDate] =
    useState<LowestPricesByDate>({});
  const [selectedItemDetails, setSelectedItemDetails] = useState({
    location: "",
    checkinDate: "",
    nights: 0,
    checkoutDate: "",
    duration: 0,
    price: 0,
    hotelName: "",
    operator: "",
    board: [""],
    room: [""],
  });
  const generateTripDetails = () => {
    if (!selectedHotelOffer) return "";

    const tripDetailsString = `Tallinn - ${selectedItemDetails.location} \n${
      selectedItemDetails.checkinDate
    } - ${selectedItemDetails.checkoutDate}(${
      selectedItemDetails.nights
    } ööd)\n${selectedItemDetails.hotelName}\n${
      boardTypeMapping[selectedItemDetails.board[0] as BoardType]
    }\n${selectedItemDetails.room}\n${
      selectedItemDetails.price
    } €/inimese kohta\n${adults} täiskasvanut\n`;

    return tripDetailsString;
  };
  const calculateAveragePrice = (): number => {
    const dates = Object.keys(lowestPricesByDate);

    if (dates.length === 0) return 0;

    const totalPrices = dates.reduce(
      (accumulator, currentDate) =>
        accumulator + lowestPricesByDate[currentDate],
      0
    );

    const averagePrice = totalPrices / dates.length;
    return averagePrice;
  };

  const averagePrice = calculateAveragePrice();
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    name_dob: "",
    inquery: "",
    trip_data: "",
    offerOperator: `${selectedItemDetails.operator}`,
  });

  const [validEmail, setValidEmail] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const onFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const value = e.target.value;

    if (field === "trip_data") {
      const tripDetails = generateTripDetails();
      setFormData({ ...formData, [field]: tripDetails });
    } else {
      setFormData({ ...formData, [field]: value });

      if (field === "email") {
        setValidEmail(validateEmail(value));
      }
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const sentFromURL = window.location.href;

    const formDataToSend = {
      ...formData,
      sentFromURL,
    };
    const emailIsValid = validateEmail(formData.email);

    if (!formData.email.trim()) {
      setValidEmail(false);
      return;
    }
    if (!emailIsValid) {
      return;
    }
    try {
      const response = await fetch("/api/reisid/query", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formDataToSend),
      });

      if (response.ok) {
        setIsFormSubmitted(true);
        console.log("Form data submitted successfully");
      } else {
        console.error("Failed to submit form data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };
  const validateEmail = (email: string) => {
    if (!email.trim()) return false;
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const adultsParam = queryParams.get("adults") || 0;
    const childrenParam = queryParams.get("children") || 0;

    setAdults(Number(adultsParam));
    setChildren(Number(childrenParam));
  }, []);

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const [isLoading, setIsLoading] = useState(false);
  const fetchFlightPrices = async (date: Date) => {
    setIsLoading(true);
    try {
      const formattedEndDate = formatDate(
        new Date(date.getTime() + 14 * 24 * 60 * 60 * 1000)
      );

      const queryParams = new URLSearchParams(window.location.search);
      const depart = queryParams.get("depart") || "TLL";
      const arrive = queryParams.get("arrive");

      const hotelId = window.location.pathname.split("/")[2];

      const selectedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
      const selectedYear = date.getFullYear().toString();

      const monthParameter = `${selectedMonth}-${selectedYear}`;

      const url = `/api/reisid/hoteloffers/${hotelId}?depart=${depart}&arrive=${arrive}&startDate=${date}&endDate=${formattedEndDate}&nightsMin=7&nightsMax=14&adults=2&children=0&child1=0&child2=0&child3=0&priceMin=0&priceMax=10000&month=${monthParameter}`;

      const response = await fetch(url);
      const data = await response.json();

      setIsLoading(false);
      setHotelOffers(data);
    } catch (error) {
      console.error("Error fetching flight prices:", error);
    }
  };

  const handleMonthChange = async (date: Date) => {
    setStartDate(date);
    await fetchFlightPrices(date);
  };
  const handleDateChange = async (date: Date) => {
    setStartDate(date);

    const selectedDate = formatDate(date);

    const hotelOffersMatchingDate = hotelOffers.filter((offer) => {
      return offer.checkinDate === selectedDate;
    });

    setFilteredHotelOffers(hotelOffersMatchingDate);
  };

  useEffect(() => {
    fetchFlightPrices(startDate);
  }, []);

  const mapLowestPricesByDate = (offers: HotelOffer[]): LowestPricesByDate => {
    const lowestPricesByDate: LowestPricesByDate = {};
    offers.forEach((offer) => {
      const { checkinDate, price } = offer;

      if (
        !lowestPricesByDate[checkinDate] ||
        price < lowestPricesByDate[checkinDate]
      ) {
        lowestPricesByDate[checkinDate] = price;
      }
    });

    return lowestPricesByDate;
  };

  useEffect(() => {
    const updatedLowestPrices = mapLowestPricesByDate(hotelOffers);
    setLowestPricesByDate(updatedLowestPrices);
  }, [hotelOffers]);
  useEffect(() => {
    if (hotelOffers.length > 0 && !startDate) {
      const earliestDate = new Date(hotelOffers[0].checkinDate);
      setStartDate(earliestDate);
    }
  }, [hotelOffers, startDate]);
  const dayCustomStyle = (date: Date): string | null => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedLocalDate = `${day}-${month}-${year}`;
    const customText = lowestPricesByDate[formattedLocalDate]?.toString();
    const averagePrice = calculateAveragePrice();

    if (customText && parseInt(customText) < averagePrice) {
      return "custom-day-sale";
    }

    return customText ? "custom-day" : null;
  };

  const renderCustomDayContents = (
    dayOfMonth: number,
    date?: Date | undefined
  ): React.ReactNode => {
    if (!date) return null;

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedLocalDate = `${day}-${month}-${year}`;

    const customDatesData = hotelOffers.reduce((acc, offer) => {
      const offerDay = offer.checkinDate.split("-")[0];
      const offerMonth = offer.checkinDate.split("-")[1];
      const offerYear = offer.checkinDate.split("-")[2];

      const formattedOfferDate = `${offerDay}-${offerMonth}-${offerYear}`;

      if (formattedLocalDate === formattedOfferDate) {
        acc[formattedOfferDate] = offer.price.toString();
      }

      return acc;
    }, {} as Record<string, string>);

    const customText = lowestPricesByDate[formattedLocalDate];

    return (
      <div style={{ position: "relative" }}>
        <div>{dayOfMonth}</div>

        {isLoading ? (
          <div
            style={{
              fontSize: "10px",
              position: "absolute",
              inset: "auto 0 0 auto",
              color: "var(--clr-primary)",
              transform: "translateY(92%) translateX(25%)",
            }}
          >
            <div className="loading-dots">
              <div className="loading-dot"></div>
              <div className="loading-dot"></div>
              <div className="loading-dot"></div>
            </div>
          </div>
        ) : (
          customText && (
            <div
              style={{
                fontSize: "10px",
                position: "absolute",
                inset: "auto 0 0 auto",
                color: "var(--clr-primary)",
                transform: "translateY(92%)",
              }}
            >
              {customText} €
            </div>
          )
        )}
      </div>
    );
  };

  const cellTitleStyle = {
    padding: "8px",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Lato",
    color: "#2E2E2E",
  };
  const cellBodyStyle = {
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Lato",
    color: "#645F6C",
    border: "none",
  };
  const cellTitlePriceStyle = {
    padding: "8px",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Lato",
    color: "var(--clr-primary)",
  };
  const cellBodyPriceStyle = {
    padding: "8px",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Lato",
    color: "var(--clr-primary)",
    border: "none",
  };
  const filterPastDates = (date: any) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const startOfMonth = new Date(currentYear, currentMonth, 1);

    return date >= startOfMonth;
  };

  const handleRadioChange = (selectedItem: any) => {
    const {
      checkinDate: rawCheckinDate,
      nights,
      price,
      location,
      hotelName,
      board,
      room,
      operator,
    } = selectedItem;

    const [day, month, year] = rawCheckinDate.split("-").map(Number);
    const checkinDate = new Date(year, month - 1, day);

    const checkoutDate = new Date(
      checkinDate.getTime() + nights * 24 * 60 * 60 * 1000
    );
    const formattedCheckoutDate = formatDate(checkoutDate);

    const duration = nights;

    const tripDetailsString = `Tallinn - ${location} \n${formatDate(
      checkinDate
    )} - ${formattedCheckoutDate}(${duration} ööd)\n${hotelName}\n${
      boardTypeMapping[board[0] as BoardType]
    }\n${room}\n${price} €/inimese kohta\n`;

    setSelectedItemDetails({
      location,
      checkinDate: formatDate(checkinDate),
      nights: duration,
      checkoutDate: formattedCheckoutDate,
      duration,
      price,
      hotelName,
      board,
      room,
      operator,
    });

    setSelectedHotelOffer(selectedItem.id);
    setFormData({
      ...formData,
      trip_data: tripDetailsString,
      offerOperator: operator || "-",
    });
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlStartDate = urlParams.get("startDate");

    if (urlStartDate) {
      const [day, month, year] = urlStartDate.split("-");
      const parsedDay = parseInt(day, 10);
      const parsedMonth = parseInt(month, 10) - 1;
      const parsedYear = parseInt(year, 10);

      const selectedStartDate = new Date(parsedYear, parsedMonth, parsedDay);
      setStartDate(selectedStartDate);
    }
  }, []);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";

      const newHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${newHeight}px`;
    }
  }, [formData.trip_data]);
  return (
    <div className="booking">
      <div className="booking__title">
        <h3>Broneeri reis</h3>
      </div>
      <div className="booking__undertitle">
        <p>1. Vali alguskuupäev</p>
      </div>
      <div className="booking__calendar">
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          onMonthChange={handleMonthChange}
          filterDate={filterPastDates}
          locale={et}
          startDate={startDate}
          endDate={endDate}
          inline
          dayClassName={dayCustomStyle}
          renderDayContents={(dayOfMonth, date) =>
            renderCustomDayContents(dayOfMonth, date)
          }
        />
        <div className="booking__calendar--legend">
          <p>Soodushind</p>
          <p>Tavahind</p>
        </div>
      </div>
      <div className="booking__undertitle">
        <p>2. Vali pakett</p>
      </div>

      <div className="booking__flights">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell sx={{ ...cellTitleStyle }}>Kestvus</TableCell>
                <TableCell sx={{ ...cellTitleStyle }}>Toitlustus</TableCell>
                <TableCell sx={{ ...cellTitleStyle }}>Tuba</TableCell>
                <TableCell sx={{ ...cellTitlePriceStyle, width: "20%" }}>
                  Hind *
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredHotelOffers.map((item) => (
                <TableRow
                  key={item.id}
                  onClick={() => handleRadioChange(item)}
                  sx={{
                    "&:hover": { backgroundColor: "#FDF9FE" },
                    "&:focus": { border: "2px solid #C552E1" },
                    "&:active": { backgroundColor: "rgba(103, 80, 161, 0.12)" },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    sx={{
                      ...cellBodyStyle,
                      padding: "8px",
                    }}
                  >
                    <Radio
                      checked={selectedHotelOffer === item.id}
                      onChange={() => handleRadioChange(item)}
                      sx={{
                        "&:hover": {
                          background: "#F5F5F5",
                        },
                        "&:active": {
                          background: "rgba(103, 80, 161, 0.12);",
                        },
                        "&.Mui-checked": {
                          color: "var(--clr-primary)",
                        },
                      }}
                      icon={<UnChecked />}
                      checkedIcon={<Checked />}
                    />
                  </TableCell>
                  <TableCell sx={{ ...cellBodyStyle, padding: "8px" }}>
                    <p>{item.nights} ööd</p>
                  </TableCell>
                  <TableCell sx={{ ...cellBodyStyle, padding: "0px" }}>
                    <p>{boardTypeMapping[item.board[0] as BoardType]}</p>
                  </TableCell>
                  <TableCell
                    sx={{ ...cellBodyStyle, padding: "4px 8px", width: "20%" }}
                    style={{ textTransform: "lowercase" }}
                  >
                    <p>{item.room}</p>
                  </TableCell>
                  <TableCell
                    sx={{ ...cellBodyPriceStyle, padding: "8px", width: "15%" }}
                  >
                    <p>{item.price}€</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {selectedHotelOffer && (
            <div
              style={{
                padding: "8px 0 20px 0",
                display: "flex",
                width: "100%",

                justifyContent: "end",
              }}
            >
              <span
                style={{
                  display: "flex",
                  fontStyle: "italic",
                  marginRight: "0.75rem",
                  color: "#645F6C",
                  fontWeight: "600",
                }}
              >
                * inimese kohta
              </span>
            </div>
          )}
        </TableContainer>
      </div>
      <div className="booking__undertitle">
        <p>3. Küsi pakkumist</p>
      </div>
      <div className="booking__form">
        <p>
          Meil on hea meel, et oled leidnud endale sobiva pakkumise! Jäta enda
          kontakt ja meie abivalmis reisikonsultant võtab Sinuga esimesel
          võimalusel ühendust.
        </p>
        <form
          onSubmit={handleFormSubmit}
          style={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}
        >
          <div className="booking__form--details">
            <label htmlFor="trip_data">Reisi detailid: *</label>
            <textarea
              id="trip_data"
              placeholder="Reisi detailid"
              ref={textareaRef}
              style={{ minHeight: "125px", resize: "none" }}
              value={formData.trip_data || generateTripDetails()}
              name="trip_data"
              onChange={(e) => onFormChange(e, "trip_data")}
              readOnly
            />
          </div>
          <div className="booking__form--email">
            <label htmlFor="email">E-post: *</label>
            <input
              type="email"
              id="email"
              placeholder="Sisesta e-posti aadress"
              onChange={(e) => onFormChange(e, "email")}
              style={
                !validEmail && !isFormSubmitted
                  ? { border: "2px solid red" }
                  : {}
              }
            />
            {!validEmail && (
              <div style={{ color: "red" }}>
                Palun sisesta kehtiv e-posti aadress
              </div>
            )}
          </div>
          <div className="booking__form--phone">
            <label htmlFor="phone">Telefon:</label>
            <input
              type="phone"
              id="phone"
              onChange={(e) => onFormChange(e, "phone")}
              placeholder="Sisesta telefoninumber"
            />
          </div>
          <div className="booking__form--people">
            <label htmlFor="people">Reisijate nimed ja vanused:</label>
            <textarea
              name="name_dob"
              value={formData.name_dob}
              placeholder="Soovi korral sisesta reisijate nimed ja vanused"
              onChange={(e) => onFormChange(e, "name_dob")}
            />
          </div>
          <div className="booking__form--additional">
            <label htmlFor="additional">Kommentaarid:</label>
            <textarea
              name="inquery"
              value={formData.inquery}
              onChange={(e) => onFormChange(e, "inquery")}
              placeholder="Siin saad vajadusel täpsustada oma erisoove
            seoses päringu ja eesootava reisiga"
            />
          </div>
          <div className="booking__button">
            <button
              type="submit"
              className="btn-secondary btn"
              disabled={isFormSubmitted}
            >
              {isFormSubmitted ? "Päring edukalt saadetud" : "Saada päring"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResultsBooking;
