import React, { FC } from "react";
import Hero from "../components/LandingPageComponents/Hero";
import Offers from "../components/LandingPageComponents/Offers";
import Campaigns from "../components/LandingPageComponents/Campaigns";
import ClientReview from "../components/LandingPageComponents/ClientReview";
import Newsletter from "../components/LandingPageComponents/Newsletter";

const Landing: FC = (): JSX.Element => {
  return (
    <div className="landing">
      <Hero />
      <Campaigns />
      <Offers />
      {/* <ClientReview /> */}
      <Newsletter />
    </div>
  );
};

export default Landing;
