import React, { FC, useEffect, useState } from "react";
import { ResultsTypes, offerItems } from "../../types/pakkumisedTypes/offers";
import arrow from "../../assets/icons/arrowR.svg";
import { Link } from "react-router-dom";
import turgi from "../../assets/locations/turgi.png";
import egiptus from "../../assets/locations/egiptus.png";
import bulgaaria from "../../assets/locations/bulgaaria.png";
import kreeka from "../../assets/locations/kreeka.png";
import aue from "../../assets/locations/aue.png";
import hispaania from "../../assets/locations/hispaania.png";
import Kypros from "../../assets/locations/Kypros.jpeg";
import portugal from "../../assets/locations/Portugal.png";
import Tenerife from "../../assets/locations/Tenerife.jpeg";

const offers: offerItems[] = [
  { location: "Türgi", image: turgi, price: 303, code: "TR" },
  { location: "Egiptus", image: egiptus, price: 379, code: "EG" },
  { location: "Bulgaaria", image: bulgaaria, price: 385, code: "BG" },
  { location: "Kreeka", image: kreeka, price: 485, code: "GR" },
  { location: "Araabia Ühendemiraadid", image: aue, price: 812, code: "AE" },
  { location: "Hispaania", image: hispaania, price: 804, code: "ES" },
  { location: "Portugal", image: portugal, price: 779, code: "PT" },
  { location: "Kypros", image: Kypros, price: 720, code: "CY" },
  { location: "Kanaari saared", image: Tenerife, price: 705, code: "ES" },
];

const Offers: FC = (): JSX.Element => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [expand, setExpand] = useState<boolean>(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const currentDate = new Date();
    const oneMonthLater = new Date();
    oneMonthLater.setMonth(currentDate.getMonth() + 12);

    setStartDate(formatDate(currentDate));
    setEndDate(oneMonthLater);
  }, []);

  const formatDate = (date: Date): string => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day < 10 ? "0" + day : day}-${
      month < 10 ? "0" + month : month
    }-${year}`;
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const calculateDisplayCount = () => {
    if (window.innerWidth < 1024) {
      return expand ? offers.length : 3;
    } else {
      return expand ? offers.length : 6;
    }
  };

  const formattedStartDate = formatDate(new Date());
  const formattedEndDate = formatDate(endDate);

  return (
    <div className="offer-sm">
      <div className="offer-sm__title">
        <h1>Parima hinnaga pakkumised</h1>
      </div>
      <div className="offer-sm__cards">
        {Array.isArray(offers) && offers.length > 0 ? (
          offers.slice(0, calculateDisplayCount()).map((offer, index) => (
            <Link
              to={`/results?depart=TLL&arrive=${offer.code}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&nightsMin=7&nightsMax=14&adults=2&children=0&child1=0&child2=0&child3=0&priceMin=0&priceMax=10000`}
              key={index}
            >
              <div className="offer-sm__card">
                <div className="offer-sm__img">
                  <img src={offer.image} alt={`Offer ${index}`} />
                </div>
                <div className="offer-sm__card--title">
                  <h2>{offer.location}</h2>
                </div>
                <p className="offer-sm__price">Alates {offer.price} €</p>
              </div>
            </Link>
          ))
        ) : (
          <p>No offers available</p>
        )}
      </div>

      <div className="offer-sm__button">
        <button className="btn" onClick={toggleExpand}>
          {expand ? "Vähem pakkumisi" : "Kõik pakkumised"}{" "}
          <img
            src={arrow}
            alt=""
            style={{ transform: expand ? "rotate(-90deg)" : "rotate(90deg)" }}
          />
        </button>
      </div>
    </div>
  );
};

export default Offers;
