import React, { FC, useState, useEffect } from "react";
import arrow from "../../assets/icons/arrowR.svg";

import { Link } from "react-router-dom";
import mockCampaigns from "../../utils/kampaaniad";

const Campaigns: FC = (): JSX.Element => {
  const [expand, setExpand] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const calculateDisplayCount = () => {
    if (windowWidth < 1024) {
      return expand ? mockCampaigns.length : 3;
    } else {
      return expand ? mockCampaigns.length : 3;
    }
  };

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  return (
    <div className="campaigns">
      <div className="campaigns__title">
        <h1>Reisihunt korraldab</h1>
      </div>
      <div className="campaigns__grid">
        {Array.isArray(mockCampaigns) && mockCampaigns.length > 0 ? (
          mockCampaigns
            .slice(0, calculateDisplayCount())
            .map((campaign, index) => (
              <Link to={campaign.link} className="campaigns__wrapper">
                <div className="campaigns__card" key={index}>
                  <div className="campaigns__card--image">
                    <img src={campaign.imageUrl} alt={campaign.title} />
                  </div>
                  <div className="campaigns__card--price">
                    <p>{campaign.text}</p>
                  </div>
                  <div className="campaigns__card--title">
                    <h2>{campaign.title}</h2>
                  </div>
                </div>
              </Link>
            ))
        ) : (
          <p>No offers available</p>
        )}
      </div>

      <div className="campaigns__button">
        <button className="btn" onClick={toggleExpand}>
          {expand ? "Vähem kampaaniaid" : "Kõik kampaaniad"}{" "}
          <img
            src={arrow}
            alt=""
            style={{ transform: expand ? "rotate(-90deg)" : "rotate(90deg)" }}
          />
        </button>
      </div>
    </div>
  );
};

export default Campaigns;
