import React, { FC, useEffect, useRef, useState } from "react";
import arrow from "../../../assets/icons/arrowDpurple.svg";
import close from "../../../assets/icons/closeGray.svg";
import search from "../../../assets/icons/search.svg";
import DatePicker from "react-datepicker";
import et from "date-fns/locale/et";

import { format, parse } from "date-fns";
import qs from "qs";
import minus from "../../../assets/icons/minus.svg";
import plus from "../../../assets/icons/plus.svg";

import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";

const countries = [
  { value: "all", label: "Kõik" },
  { value: "EG", label: "Egiptus" },
  { value: "HRG", label: "Hurghada" },
  { value: "RMF", label: "Marsa Alam" },
  { value: "SSH", label: "Sharm El Sheikh" },
  { value: "TR", label: "Türgi" },
  { value: "AE", label: "AÜE" },
  { value: "MA", label: "Maroko" },
  { value: "BG", label: "Bulgaaria" },
  { value: "ES", label: "Hispaania" },
  { value: "IT", label: "Itaalia" },
  { value: "ID", label: "Indonesia" },
  { value: "CN", label: "Kanaari saared" },
  { value: "GR", label: "Kreeka" },
  { value: "CU", label: "Kuuba" },
  { value: "CY", label: "Küpros" },
  { value: "MU", label: "Mauritius" },
  { value: "MX", label: "Mehhiko" },
  { value: "ME", label: "Montenegro" },
  { value: "PT", label: "Portugal" },
  { value: "SC", label: "Sei\u0161ellid" },
  { value: "LK", label: "Sri Lanka" },
  { value: "TH", label: "Tai" },
  { value: "TZ", label: "Tansaania" },
  { value: "TN", label: "Tuneesia" },
  { value: "VN", label: "Vietnam" },
];
const start = [{ value: "TLL", label: "Tallinn" }];

const HeroSearchSm: FC = (): JSX.Element => {
  const [isDepartModalOpen, setDepartModalOpen] = useState(false);
  const location = useLocation();

  const [isDestinationModalOpen, setDestinationModalOpen] = useState(false);
  const [isTravelersModalOpen, setTravelersModalOpen] = useState(false);
  const [selectedDepartCountry, setSelectedDepartCountry] = useState("Tallinn");
  const [selectedDestinationCountry, setSelectedDestinationCountry] =
    useState("Kõik");
  const [isNightModalOpen, setNightModalOpen] = useState(false);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [selectedStart, setSelectedStart] = useState("Tallinn");
  const [selectedEnd, setSelectedEnd] = useState("Kõik");
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [isEndCalendarOpen, setEndCalendarOpen] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(() => {
    const currentDate = new Date();
    const nextWeekDate = new Date(
      currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
    );
    return nextWeekDate;
  });

  const [nightStart, setNightStart] = useState(7);
  const [nightEnd, setNightEnd] = useState(14);
  const [showResultsView, setShowResultsView] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [child1, setChild1] = useState(0);
  const [child2, setChild2] = useState(0);
  const [child3, setChild3] = useState(0);
  const decreaseNightStart = (event: any) => {
    event.stopPropagation();

    const newNightStart = nightStart - 1;

    if (newNightStart >= 1 && newNightStart <= nightEnd - 1) {
      setNightStart(newNightStart);
    }
  };
  const departModalRef = useRef<HTMLDivElement>(null);

  const increaseNightStart = (event: any) => {
    event.stopPropagation();

    const newNightStart = nightStart + 1;

    if (newNightStart <= nightEnd - 1) {
      setNightStart(newNightStart);
    }
  };

  const decreaseNightEnd = (event: any) => {
    event.stopPropagation();

    const newNightEnd = nightEnd - 1;

    if (newNightEnd >= nightStart + 1) {
      setNightEnd(newNightEnd);
    }
  };

  const increaseNightEnd = (event: any) => {
    event.stopPropagation();

    const newNightEnd = nightEnd + 1;

    setNightEnd(newNightEnd);
  };
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath === "/results") {
      setShowResultsView(true);
    } else {
      setShowResultsView(false);
    }
  }, []);

  const handleOpenCalendar = () => {
    setCalendarOpen(true);
    setOpenModal(false);
  };

  const handleOpenEndCalendar = () => {
    setEndCalendarOpen(true);
    setOpenModal(false);
  };

  const handleCloseCalendar = () => {
    setCalendarOpen(false);
    setOpenModal(true);
  };
  const handleCloseEndCalendar = () => {
    setEndCalendarOpen(false);
    setOpenModal(true);
  };
  const handleCloseEnd = () => {
    setEndCalendarOpen(false);
    setCalendarOpen(false);
    setDepartModalOpen(false);
    setDestinationModalOpen(false);
  };

  const handleDepartOpenModal = () => {
    setDepartModalOpen(true);
    setOpenModal(false);
  };
  const handleNightOpenModal = () => {
    setNightModalOpen(true);
    setOpenModal(false);
  };
  const handleNightCloseModal = () => {
    setNightModalOpen(false);
    setOpenModal(true);
  };
  const handleNightClose = () => {
    setNightModalOpen(false);
  };
  const handleDestinationOpenModal = () => {
    setDestinationModalOpen(true);
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setDepartModalOpen(false);
    setDestinationModalOpen(false);
    setOpenModal(true);
  };

  const handleDepartSelectChange = (selectedCountry: string) => {
    setSelectedDepartCountry(selectedCountry);
    handleCloseModal();
  };
  const handleSelect = (selectedCountry: string) => {
    setSelectedDepartCountry(selectedCountry);
    setDepartModalOpen(false);
    setDestinationModalOpen(false);
  };
  const handleTravelersModal = () => {
    setTravelersModalOpen(true);
    setOpenModal(false);
  };
  const handleTravelersCloseModal = () => {
    setTravelersModalOpen(false);
    setOpenModal(true);
  };
  const handleTravelersClose = () => {
    setTravelersModalOpen(false);
  };

  const handleDestinationSelectChange = (selectedDestination: string) => {
    const selectedLabel =
      countries.find((country) => country.value === selectedDestination)
        ?.label || selectedDestination;
    setSelectedDestinationCountry(selectedLabel);
    handleCloseModal();
  };
  const handleDestination = (selectedDestination: string) => {
    const selectedLabel =
      countries.find((country) => country.value === selectedDestination)
        ?.label || selectedDestination;
    setSelectedDestinationCountry(selectedLabel);
    setDestinationModalOpen(false);
  };
  const navigate = useNavigate();
  const decreaseAdults = () => {
    if (adults > 1) {
      setAdults(adults - 1);
    }
  };

  const increaseAdults = () => {
    setAdults(adults + 1);
  };

  const decreaseChildren = () => {
    if (children > 0) {
      setChildren(children - 1);
    }
  };
  const increaseChild = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setChild1(child1 + 1);
  };

  const decreaseChild = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (child1 > 0) {
      setChild1(child1 - 1);
    }
  };
  const increaseChild2 = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setChild2(child2 + 1);
  };

  const decreaseChild2 = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (child2 > 0) {
      setChild2(child2 - 1);
    }
  };
  const increaseChild3 = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setChild3(child3 + 1);
  };

  const decreaseChild3 = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (child3 > 0) {
      setChild3(child3 - 1);
    }
  };
  const increaseChildren = () => {
    setChildren(children + 1);
  };
  const handleSearch = () => {
    const selectedStartValue =
      start.find((country) => country.label === selectedStart)?.value || "";
    const selectedEndValue =
      countries.find((country) => country.label === selectedDestinationCountry)
        ?.value || "";

    const queryParams = {
      depart: selectedStartValue,
      arrive: selectedEndValue,
      startDate: format(startDate, "dd-MM-yyyy"),
      endDate: format(endDate, "dd-MM-yyyy"),
      nightsMin: nightStart,
      nightsMax: nightEnd,
      adults: adults,
      children: children,
      child1: child1,
      child2: child2,
      child3: child3,
      priceMin: 0,
      priceMax: 10000,
    };

    const urlString = qs.stringify(queryParams);
    const url = `/results?${urlString}`;
    sessionStorage.setItem("searchParams", JSON.stringify(queryParams));
    navigate(url);
    handleModal();
  };
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "numeric",
    month: "long",
  };
  const filterPastDates = (date: any) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const startOfMonth = new Date(currentYear, currentMonth, 1);

    return date >= startOfMonth;
  };

  const totalTravelers = children > 0 ? `${adults} + ${children}` : `${adults}`;
  const getLabelFromValue = (value: any) => {
    const country = countries.find((country) => country.value === value);
    const startValue = start.find((city) => city.value === value);

    return country ? country.label : startValue ? startValue.label : value;
  };
  useEffect(() => {
    if (location.pathname === "/results") {
      const searchParams = new URLSearchParams(location.search);

      const depart = getLabelFromValue(searchParams.get("depart") || "Tallinn");
      const arrive = getLabelFromValue(searchParams.get("arrive") || "Kõik");
      const nightsMin = parseInt(searchParams.get("nightsMin") || "7", 10);
      const nightsMax = parseInt(searchParams.get("nightsMax") || "14", 10);
      const adults = parseInt(searchParams.get("adults") || "2", 10);
      const children = parseInt(searchParams.get("children") || "0", 10);
      const child1 = parseInt(searchParams.get("child1") || "0", 10);
      const child2 = parseInt(searchParams.get("child2") || "0", 10);
      const child3 = parseInt(searchParams.get("child3") || "0", 10);
      const startDateParam = searchParams.get("startDate");
      const endDateParam = searchParams.get("endDate");

      const parsedStartDate = startDateParam
        ? parse(startDateParam, "dd-MM-yyyy", new Date())
        : new Date();
      let parsedEndDate = endDateParam
        ? parse(endDateParam, "dd-MM-yyyy", new Date())
        : new Date();

      setSelectedStart(depart);
      setSelectedDestinationCountry(arrive);
      setNightStart(nightsMin);
      setNightEnd(nightsMax);
      setAdults(adults);
      setChildren(children);
      setChild1(child1);
      setChild2(child2);
      setChild3(child3);
      setStartDate(parsedStartDate);
      setEndDate(parsedEndDate);
      if (parsedEndDate < parsedStartDate) {
        parsedEndDate = new Date(
          parsedStartDate.getTime() + 7 * 24 * 60 * 60 * 1000
        );
      }
      setEndDate(parsedEndDate);
    } else {
      setSelectedStart("Tallinn");
      setSelectedEnd("Kõik");
      setNightStart(7);
      setNightEnd(14);
      setAdults(2);
      setChildren(0);
      setChild1(0);
      setChild2(0);
      setChild3(0);
      setStartDate(new Date());
      setEndDate(endDate);
    }
  }, [location]);
  const NewDateOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "2-digit",
  };
  const modalStates = {
    openModal,
    isDepartModalOpen,
    isDestinationModalOpen,
    isCalendarOpen,
    isEndCalendarOpen,
    isNightModalOpen,
    isTravelersModalOpen,
  };

  const anyModalOpen = Object.values(modalStates).some((state) => state);
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        handleCloseEnd();
        handleNightClose();
        handleTravelersClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      {anyModalOpen && <div className="overlay" />}

      {showResultsView ? (
        <>
          <div className="hero-results" onClick={handleModal}>
            <div className="hero-results__icon">
              <img src={search} alt="" />
            </div>
            <div className="hero-results__search">
              <div className="hero-results__top">
                <p>
                  {selectedDepartCountry || "Tallinn"} -{" "}
                  {selectedDestinationCountry || "Kõik"}
                </p>
              </div>
              <div className="hero-results__bottom">
                <p>
                  {startDate.toLocaleDateString("et-EE", NewDateOptions)} -
                  {endDate.toLocaleDateString("et-EE", NewDateOptions)}
                </p>
                <p>
                  {nightStart}
                  {-nightEnd} ööd
                </p>
                <p>{totalTravelers} inimest</p>
              </div>
            </div>
          </div>
          {openModal && (
            <div className="hero-modal">
              <div className="hero-modal__img">
                <img src={close} alt="" onClick={handleModal} />
              </div>
              <div className="hero-modal__title">
                <p>otsingu parameetrid:</p>
              </div>
              <div className="hero-modal__param">
                <div className="hero-modal__param--depart">
                  <label>Lähtekoht:</label>
                  <div className="hero-search__select">
                    <div className="hero-search__select--button">
                      <button onClick={handleDepartOpenModal}>
                        {selectedDepartCountry || "Tallinn"}
                        <img src={arrow} alt=" " />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hero-modal__param--arrive">
                  <label>Sihtkoht:</label>
                  <div className="hero-search__select">
                    <div className="hero-search__select--button">
                      <button onClick={handleDestinationOpenModal}>
                        {selectedDestinationCountry || "Kõik"}
                        <img src={arrow} alt=" " />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hero-modal__param--date">
                  <label>Alguskuupäev:</label>
                  <div className="hero-search__select">
                    <div className="hero-search__select--button">
                      <button onClick={handleOpenCalendar}>
                        {startDate.toLocaleDateString("et-EE", dateOptions)}
                        <img src={arrow} alt=" " />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hero-modal__param--date">
                  <label>Lõppkuupäev:</label>
                  <div className="hero-search__select">
                    <div className="hero-search__select--button">
                      <button onClick={handleOpenEndCalendar}>
                        {endDate.toLocaleDateString("et-EE", dateOptions)}
                        <img src={arrow} alt=" " />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hero-modal__param--nights">
                  <label>Kestvus:</label>
                  <div className="hero-search__select">
                    <div className="hero-search__select--button">
                      <button onClick={handleNightOpenModal}>
                        <div className="hero-modal__param--nights-layout">
                          <p>{nightStart}</p>
                          &nbsp;
                          <span>-</span>
                          &nbsp;
                          <p>{nightEnd}</p>
                          &nbsp;
                          <span>ööd</span>
                        </div>
                        <img src={arrow} alt=" " />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hero-modal__param--travelers">
                  <label>Reisijad:</label>
                  <div className="hero-search__select">
                    <div className="hero-search__select--button">
                      <button onClick={handleTravelersModal}>
                        <div className="hero-modal__param--nights-layout">
                          <p>{totalTravelers} inimest</p>
                        </div>
                        <img src={arrow} alt=" " />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-modal__param--buttons">
                <button
                  className="hero-modal__param--buttons-back"
                  onClick={handleModal}
                >
                  Tagasi
                </button>

                <button
                  className="hero-modal__param--buttons-submit btn"
                  onClick={handleSearch}
                >
                  Otsi
                </button>
              </div>
            </div>
          )}

          {isDepartModalOpen && (
            <div className="modal">
              <div className="modal__button">
                <label>Vali Lähtekoht:</label>
                <img onClick={handleCloseModal} src={close} alt="" />
              </div>
              <ul>
                {start.map((country) => (
                  <li
                    key={country.value}
                    onClick={() =>
                      handleDepartSelectChange(country.label as any)
                    }
                    className={
                      selectedDepartCountry === country.label ? "selected" : ""
                    }
                  >
                    {country.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {isDestinationModalOpen && (
            <div className="modal-large">
              <div className="modal-large__button">
                <label>Vali Sihtkoht:</label>
                <img onClick={handleCloseModal} src={close} alt="" />
              </div>
              <ul>
                {countries.map((country) => (
                  <li
                    key={country.value}
                    onClick={() =>
                      handleDestinationSelectChange(country.value as any)
                    }
                    className={
                      selectedDestinationCountry === country.value
                        ? "selected"
                        : ""
                    }
                  >
                    {country.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {isCalendarOpen && (
            <div className="modal">
              <div className="modal__button">
                <label>Vali Alguskuupäev:</label>
                <img onClick={handleCloseCalendar} src={close} alt="" />
              </div>
              <div className="filter-calendar">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date as any)}
                  filterDate={filterPastDates}
                  locale={et}
                  dateFormat="EEEEE,  d. MMMM"
                  onClickOutside={handleCloseCalendar}
                  onFocus={handleOpenCalendar}
                  inline
                />
              </div>
            </div>
          )}
          {isEndCalendarOpen && (
            <div className="modal">
              <div className="modal__button">
                <label>Vali Lõppkuupäev:</label>
                <img onClick={handleCloseEndCalendar} src={close} alt="" />
              </div>
              <div className="filter-calendar">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date as any)}
                  filterDate={filterPastDates}
                  locale={et}
                  dateFormat="EEEEE,  d. MMMM"
                  onClickOutside={handleCloseEndCalendar}
                  onFocus={handleOpenEndCalendar}
                  inline
                />
              </div>
            </div>
          )}
          {isNightModalOpen && (
            <div className="modal" ref={departModalRef}>
              <div className="modal__button">
                <label>Vali Kestvus:</label>
                <img onClick={handleNightCloseModal} src={close} alt="" />
              </div>
              <div className="hero-search__select">
                <div className="duration__nights">
                  <div className="duration-start">
                    <button
                      onClick={(event) => decreaseNightStart(event)}
                      style={{ background: "none" }}
                    >
                      <img src={minus} alt="" />
                    </button>
                    <div className="nights">
                      <p>{nightStart}</p>
                      <p>ööd</p>
                    </div>
                    <button
                      onClick={(event) => increaseNightStart(event)}
                      style={{ background: "none" }}
                    >
                      <img src={plus} alt="" />
                    </button>
                  </div>
                  <span>kuni</span>
                  <div className="duration-start">
                    <button
                      onClick={(event) => decreaseNightEnd(event)}
                      style={{ background: "none" }}
                    >
                      <img src={minus} alt="" />
                    </button>
                    <div className="nights">
                      <p>{nightEnd}</p>
                      <p>ööd</p>
                    </div>
                    <button
                      onClick={(event) => increaseNightEnd(event)}
                      style={{ background: "none" }}
                    >
                      <img src={plus} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isTravelersModalOpen && (
            <div className="hero-search__select">
              <div className="modal" ref={departModalRef}>
                <div className="modal__button">
                  <label>Vali reisijad:</label>
                  <img onClick={handleTravelersCloseModal} src={close} alt="" />
                </div>
                <div className="duration__travelers">
                  <div className="duration-start">
                    <div className="adults">
                      <p>Täiskasvanuid</p>
                      <span>18+ vanuses</span>
                    </div>
                    <div className="adults__field">
                      <button
                        onClick={decreaseAdults}
                        style={{ background: "none" }}
                      >
                        <img src={minus} alt="" />
                      </button>
                      <input value={adults} readOnly />
                      <button
                        onClick={increaseAdults}
                        style={{ background: "none" }}
                      >
                        <img src={plus} alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="duration-start">
                    <div className="child">
                      <p>Lapsi</p>
                      <span>0 - 18 vanuses</span>
                    </div>
                    <div className="child__field">
                      <button
                        onClick={decreaseChildren}
                        style={{ background: "none" }}
                      >
                        <img src={minus} alt="" />
                      </button>
                      <input value={children} readOnly />
                      <button
                        onClick={increaseChildren}
                        style={{ background: "none" }}
                      >
                        <img src={plus} alt="" />
                      </button>
                    </div>
                  </div>
                  {children >= 1 && (
                    <div
                      className="duration-start"
                      style={{ marginLeft: ".5rem" }}
                    >
                      <div className="child">
                        <span>1 lapse vanuse</span>
                      </div>
                      <div className="child__field">
                        <button
                          onClick={decreaseChild}
                          style={{ background: "none" }}
                        >
                          <img src={minus} alt="" />
                        </button>
                        <input value={child1} readOnly />
                        <button
                          onClick={increaseChild}
                          style={{ background: "none" }}
                        >
                          <img src={plus} alt="" />
                        </button>
                      </div>
                    </div>
                  )}
                  {children >= 2 && (
                    <div
                      className="duration-start"
                      style={{ marginLeft: ".5rem" }}
                    >
                      <div className="child">
                        <span>2 lapse vanuse</span>
                      </div>
                      <div className="child__field">
                        <button
                          onClick={decreaseChild2}
                          style={{ background: "none" }}
                        >
                          <img src={minus} alt="" />
                        </button>
                        <input value={child2} readOnly />
                        <button
                          onClick={increaseChild2}
                          style={{ background: "none" }}
                        >
                          <img src={plus} alt="" />
                        </button>
                      </div>
                    </div>
                  )}{" "}
                  {children >= 3 && (
                    <div
                      className="duration-start"
                      style={{ marginLeft: ".5rem" }}
                    >
                      <div className="child">
                        <span>3 lapse vanuse</span>
                      </div>
                      <div className="child__field">
                        <button
                          onClick={decreaseChild3}
                          style={{ background: "none" }}
                        >
                          <img src={minus} alt="" />
                        </button>
                        <input value={child3} readOnly />
                        <button
                          onClick={increaseChild3}
                          style={{ background: "none" }}
                        >
                          <img src={plus} alt="" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="hero-search">
          <div className="hero-search__wrapper">
            <div className="hero-search__depart">
              <label>Lähtekoht:</label>
              <div className="hero-search__select">
                <div className="hero-search__select--button">
                  <button onClick={handleDepartOpenModal}>
                    {selectedDepartCountry || "Tallinn"}
                    <img src={arrow} alt=" " />
                  </button>
                </div>
                {isDepartModalOpen && (
                  <div className="modal" ref={modalRef}>
                    <div className="modal__button">
                      <label>Vali Lähtekoht:</label>
                      <img onClick={handleCloseEnd} src={close} alt="" />
                    </div>
                    <ul>
                      {start.map((country) => (
                        <li
                          key={country.value}
                          onClick={() => handleSelect(country.label as any)}
                          className={
                            selectedDepartCountry === country.label
                              ? "selected"
                              : ""
                          }
                        >
                          {country.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="hero-search__depart">
              <label>Sihtkoht:</label>
              <div className="hero-search__select">
                <div className="hero-search__select--button">
                  <button onClick={handleDestinationOpenModal}>
                    {selectedDestinationCountry || "Kõik"}
                    <img src={arrow} alt=" " />
                  </button>
                </div>
                {isDestinationModalOpen && (
                  <div className="modal" ref={modalRef}>
                    <div className="modal__button">
                      <label>Vali Sihtkoht:</label>
                      <img onClick={handleCloseEnd} src={close} alt="" />
                    </div>
                    <ul>
                      {countries.map((country) => (
                        <li
                          key={country.value}
                          onClick={() =>
                            handleDestination(country.value as any)
                          }
                          className={
                            selectedDestinationCountry === country.value
                              ? "selected"
                              : ""
                          }
                        >
                          {country.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="hero-search__depart">
              <label>Väljumine alates:</label>
              <div className="hero-search__select">
                <div className="hero-search__select--button">
                  <button onClick={handleOpenCalendar}>
                    {startDate.toLocaleDateString("et-EE", dateOptions)}
                    <img src={arrow} alt=" " />
                  </button>
                </div>
                {isCalendarOpen && (
                  <div className="modal">
                    <div className="modal__button">
                      <label>Väljumine alates:</label>
                      <img onClick={handleCloseEnd} src={close} alt="" />
                    </div>
                    <div className="filter-calendar">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date as any);
                          handleCloseEnd();
                        }}
                        filterDate={filterPastDates}
                        locale={et}
                        dateFormat="EEEEE,  d. MMMM"
                        onClickOutside={handleCloseEnd}
                        onFocus={handleOpenCalendar}
                        inline
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="hero-search__depart">
              <label>Väljumine kuni:</label>
              <div className="hero-search__select">
                <div className="hero-search__select--button">
                  <button onClick={handleOpenEndCalendar}>
                    {endDate.toLocaleDateString("et-EE", dateOptions)}
                    <img src={arrow} alt=" " />
                  </button>
                </div>
                {isEndCalendarOpen && (
                  <div className="modal">
                    <div className="modal__button">
                      <label>Väljumine kuni:</label>
                      <img onClick={handleCloseEnd} src={close} alt="" />
                    </div>
                    <div className="filter-calendar">
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => {
                          setEndDate(date as any);
                          handleCloseEnd();
                        }}
                        filterDate={filterPastDates}
                        locale={et}
                        dateFormat="EEEEE,  d. MMMM"
                        onClickOutside={handleCloseEnd}
                        onFocus={handleOpenEndCalendar}
                        inline
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="hero-search__depart">
              <label>Kestvus:</label>
              <div className="hero-search__select">
                <div className="hero-search__select--button">
                  <button onClick={handleNightOpenModal}>
                    <div className="duration">
                      <p>{nightStart}</p>
                      &nbsp;
                      <span>-</span>
                      &nbsp;
                      <p>{nightEnd}</p>
                      &nbsp;
                      <span>ööd</span>
                    </div>
                    <img src={arrow} alt=" " />
                  </button>
                </div>
                {isNightModalOpen && (
                  <div className="modal" ref={modalRef}>
                    <div className="modal__button">
                      <label>Vali Kestvus:</label>
                      <img onClick={handleNightClose} src={close} alt="" />
                    </div>
                    <div className="duration__nights">
                      <div className="duration-start">
                        <button
                          onClick={(event) => decreaseNightStart(event)}
                          style={{ background: "none" }}
                        >
                          <img src={minus} alt="" />
                        </button>
                        <div className="nights">
                          <p>{nightStart}</p>
                          <p>ööd</p>
                        </div>
                        <button
                          onClick={(event) => increaseNightStart(event)}
                          style={{ background: "none" }}
                        >
                          <img src={plus} alt="" />
                        </button>
                      </div>
                      <span>kuni</span>
                      <div className="duration-start">
                        <button
                          onClick={(event) => decreaseNightEnd(event)}
                          style={{ background: "none" }}
                        >
                          <img src={minus} alt="" />
                        </button>
                        <div className="nights">
                          <p>{nightEnd}</p>
                          <p>ööd</p>
                        </div>
                        <button
                          onClick={(event) => increaseNightEnd(event)}
                          style={{ background: "none" }}
                        >
                          <img src={plus} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="hero-search__depart">
              <label>Reisijad:</label>
              <div className="hero-search__select">
                <div className="hero-search__select--button">
                  <button onClick={handleTravelersModal}>
                    <div className="duration">
                      <p>{totalTravelers} inimest</p>
                    </div>
                    <img src={arrow} alt=" " />
                  </button>
                </div>
                {isTravelersModalOpen && (
                  <div className="modal" ref={modalRef}>
                    <div className="modal__button">
                      <label>Vali reisijad:</label>
                      <img onClick={handleTravelersClose} src={close} alt="" />
                    </div>
                    <div className="duration__travelers">
                      <div className="duration-start">
                        <div className="adults">
                          <p>Täiskasvanuid</p>
                          <span>18+ vanuses</span>
                        </div>
                        <div className="adults__field">
                          <button
                            onClick={decreaseAdults}
                            style={{ background: "none" }}
                          >
                            <img src={minus} alt="" />
                          </button>
                          <input value={adults} readOnly />
                          <button
                            onClick={increaseAdults}
                            style={{ background: "none" }}
                          >
                            <img src={plus} alt="" />
                          </button>
                        </div>
                      </div>
                      <div className="duration-start">
                        <div className="child">
                          <p>Lapsi</p>
                          <span>0 - 18 vanuses</span>
                        </div>
                        <div className="child__field">
                          <button
                            onClick={decreaseChildren}
                            style={{ background: "none" }}
                          >
                            <img src={minus} alt="" />
                          </button>
                          <input value={children} readOnly />
                          <button
                            onClick={increaseChildren}
                            style={{ background: "none" }}
                          >
                            <img src={plus} alt="" />
                          </button>
                        </div>
                      </div>
                      {children >= 1 && (
                        <div
                          className="duration-start"
                          style={{ marginLeft: ".5rem" }}
                        >
                          <div className="child">
                            <span>1 lapse vanuse</span>
                          </div>
                          <div className="child__field">
                            <button
                              onClick={decreaseChild}
                              style={{ background: "none" }}
                            >
                              <img src={minus} alt="" />
                            </button>
                            <input value={child1} readOnly />
                            <button
                              onClick={increaseChild}
                              style={{ background: "none" }}
                            >
                              <img src={plus} alt="" />
                            </button>
                          </div>
                        </div>
                      )}
                      {children >= 2 && (
                        <div
                          className="duration-start"
                          style={{ marginLeft: ".5rem" }}
                        >
                          <div className="child">
                            <span>2 lapse vanuse</span>
                          </div>
                          <div className="child__field">
                            <button
                              onClick={decreaseChild2}
                              style={{ background: "none" }}
                            >
                              <img src={minus} alt="" />
                            </button>
                            <input value={child2} readOnly />
                            <button
                              onClick={increaseChild2}
                              style={{ background: "none" }}
                            >
                              <img src={plus} alt="" />
                            </button>
                          </div>
                        </div>
                      )}{" "}
                      {children >= 3 && (
                        <div
                          className="duration-start"
                          style={{ marginLeft: ".5rem" }}
                        >
                          <div className="child">
                            <span>3 lapse vanuse</span>
                          </div>
                          <div className="child__field">
                            <button
                              onClick={decreaseChild3}
                              style={{ background: "none" }}
                            >
                              <img src={minus} alt="" />
                            </button>
                            <input value={child3} readOnly />
                            <button
                              onClick={increaseChild3}
                              style={{ background: "none" }}
                            >
                              <img src={plus} alt="" />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hero-search__button">
            <button onClick={handleSearch}>Otsi</button>
          </div>
        </div>
      )}
    </>
  );
};

export default HeroSearchSm;
