import React, { FC, useEffect, useState } from "react";
import tree from "../../../assets/icons/plamtree.svg";
import sun from "../../../assets/icons/sun.svg";
import plane from "../../../assets/icons/plane.svg";

import HeroTrustmark from "./HeroTrustmark";
import HeroSearchSm from "./HeroSearchSm";
import HeroSearch from "./HeroSearch";
import HeroAlert from "./HeroAlert";

const HeroBanner: FC = (): JSX.Element => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="hero-banner">
      <HeroAlert />
      <div className="hero-banner__icons">
        <img className="tree" src={tree} alt="" />
        <img className="sun" src={sun} alt="" />
        {/* <img className="plane" src={plane} alt="" /> */}
      </div>
      <div className="hero-banner__title">
        <h1>Muretu broneerimine koos paindlike maksetingimustega!</h1>
      </div>
      {windowWidth < 1024 ? <HeroSearchSm /> : <HeroSearch />}
      <HeroTrustmark />
    </div>
  );
};

export default HeroBanner;
