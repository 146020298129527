import React, { FC } from "react";
import fire from "../../../assets/icons/fire.svg";
import like from "../../../assets/icons/like.svg";
import smile from "../../../assets/icons/smile.svg";
import sunP from "../../../assets/icons/sunP.svg";

const HeroTrustmark: FC = (): JSX.Element => {
  return (
    <div className="hero-trustmark">
      <ul>
        <li>
          <img src={fire} alt="" /> Kõik reisipakkumised ühest kohast
        </li>
        <li>
          <img src={like} alt="" /> Parima hinna garantii
        </li>
        <li>
          <img src={smile} alt="" /> Puuduvad vahendustasud
        </li>
        <li>
          <img src={sunP} alt="" /> Madalaima hinna kalender
        </li>
      </ul>
    </div>
  );
};

export default HeroTrustmark;
