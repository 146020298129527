import React, { FC, useEffect, useState } from "react";
import HeroBanner from "./HeroComponents/HeroBanner";

const Hero: FC = (): JSX.Element => {
  const [showResultsView, setShowResultsView] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath === "/results") {
      setShowResultsView(true);
    } else {
      setShowResultsView(false);
    }

    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const heroStyle = {
    background:
      showResultsView && windowWidth < 1024 ? "var(--clr-primary)" : "",
  };

  return (
    <div className="hero" style={heroStyle}>
      <HeroBanner />
    </div>
  );
};

export default Hero;
