import React, { FC, useState, useRef, useEffect } from "react";
import sort from "../../assets/icons/sort.svg";
import close from "../../assets/icons/closeGray.svg";

interface ResultsViewProps {
  selectedFilter: string;
  handleFilterChange: (selectedFilter: string) => void;
}

const ResultsSortingSm: FC<ResultsViewProps> = ({
  handleFilterChange,
  selectedFilter,
}): JSX.Element => {
  const [isMobile, setIsMobile] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    setIsMobile(!isMobile);
  };

  const filterOptions = [
    // { value: "popularity", label: "Populaarsuse järgi" },
    // { value: "highest-category", label: "Kõrgeim kategooria esimesena" },
    { value: "price-ascending", label: "Hinna järgi kasvavalt" },
    { value: "price-descending", label: "Hinna järgi kahanevalt" },
  ];

  const handleFilterOptionClick = (filterOption: string) => {
    handleFilterChange(filterOption);
    setIsMobile(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMobile]);

  const getLabelFromValue = (value: string): string => {
    const option = filterOptions.find((option) => option.value === value);
    return option ? option.label : "";
  };
  const selectedLabel = getLabelFromValue(selectedFilter);

  return (
    <div className="sorting-sm">
      <div className="sorting-sm__button">
        <button onClick={handleOpen}>
          <img src={sort} alt="" />
          Järjestus: {selectedLabel}
        </button>
      </div>
      {isMobile && (
        <div className="modal" ref={modalRef}>
          <div className="modal__button">
            <label>Järjestus:</label>
            <img src={close} alt="" onClick={handleOpen} />
          </div>
          <ul className="options-list">
            {filterOptions.map((option) => (
              <li
                key={option.value}
                onClick={() => handleFilterOptionClick(option.value)}
                className={selectedFilter === option.value ? "active" : ""}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ResultsSortingSm;
