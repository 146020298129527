import React, { FC } from "react";
import phone from "../../assets/icons/phoneP.svg";
import mail from "../../assets/icons/mailP.svg";
import location from "../../assets/icons/location.svg";
import fb from "../../assets/icons/fb.svg";
import yt from "../../assets/icons/youtube.svg";
import insta from "../../assets/icons/insta.svg";

import novatours from "../../assets/logos/novatoursColor.png";
import joinup from "../../assets/logos/joinupColor.png";
import coral from "../../assets/logos/coraltravelColor.png";
import salva from "../../assets/logos/salvaColor.png";
import anex from "../../assets/logos/anex.svg";
import tez from "../../assets/logos/teztour.png";
import viking from "../../assets/logos/Viking Line Logo Red CMYK_page-0001.jpg";

import { Link } from "react-router-dom";

const Footer: FC = (): JSX.Element => {
  const partnerLogos = [
    { logo: anex, link: "https://www.anextour.ee", bg: "bg" },
    { logo: joinup, link: "https://joinup.ee/et" },
    { logo: coral, link: "https://coraltravel.ee" },
    { logo: tez, link: "https://www.teztour.ee" },
    { logo: viking, link: "https://www.vikingline.ee", bg: "bg" },
    { logo: novatours, link: "https://www.novatours.ee" },
    { logo: salva, link: "https://www.salva.ee/et" },
  ];

  return (
    <div className="footer">
      <div className="footer__wrapper">
        <div className="footer__links">
          <p>Kiirlingid</p>
          <ul>
            <Link to={"/heateada"}>
              <li>Hea teada</li>
            </Link>
            <li>Kampaaniad</li>
            <li>Lennuinfo</li>
          </ul>
        </div>
        <div className="footer__contact">
          <p>Kontakt</p>
          <ul>
            <li>
              <img src={phone} alt="" />
              <a href="tel:+37258982838">+372 5898 2838</a>
            </li>
            <li>
              <img src={phone} alt="" />
              <a href="tel:+37258986268">+372 5898 6268 (RUS)</a>
            </li>
            <li>
              <img src={mail} alt="" />
              <a href="mailto:info@reisihunt.ee">info@reisihunt.ee</a>
            </li>
            <li>
              <img src={location} alt="" /> E-R 10:00 - 18:00
            </li>
          </ul>
        </div>
        <div className="footer__socials">
          <p>Ole kursis</p>
          <ul>
            <Link to={"/uudiskiri"}>
              <li>
                <img src={mail} alt="" /> Telli uudiskiri
              </li>
            </Link>
            <Link to={"https://www.facebook.com/reisihunt.ee"} target="_blank">
              <li>
                <img src={fb} alt="" /> Facebook
              </li>
            </Link>
            <Link
              to={"https://www.instagram.com/reisihunt.ee/"}
              target="_blank"
            >
              <li>
                <img src={insta} alt="" /> Instagram
              </li>
            </Link>
            <Link
              to={"https://www.youtube.com/channel/UCxBL9eha1DsD4XbQXQiRg6w"}
              target="_blank"
            >
              <li>
                <img src={yt} alt="" /> Youtube
              </li>
            </Link>
          </ul>
        </div>
        <div className="footer__partners">
          <p>Partnerid</p>
          <ul>
            {partnerLogos.map((logo, index) => (
              <li key={index}>
                <a href={logo.link} target="_blank ">
                  <img
                    src={logo.logo}
                    alt={`Logo ${index + 1}`}
                    className={logo?.bg}
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
