interface SubCategory {
  text: string;
  subPath: string;
}

interface NavBarItem {
  text: string;
  isExpandable: boolean;
  subcategories?: SubCategory[];
  path?: string;
  icon?: any;
}

const navOptions: NavBarItem[] = [
  {
    text: "Avaleht",
    isExpandable: false,
    path: "/",
  },
  {
    text: "Puhkusereisid",
    isExpandable: true,
    subcategories: [
      {
        text: "Türgi",
        subPath:
          "/results?depart=TLL&arrive=TR&startDate=10-01-2024&endDate=10-01-2025&nightsMin=7&nightsMax=14&adults=2&children=0&child1=0&child2=0&child3=0&priceMin=0&priceMax=10000",
      },
      {
        text: "Egiptus",
        subPath:
          "/results?depart=TLL&arrive=EG&startDate=10-01-2024&endDate=10-01-2025&nightsMin=7&nightsMax=14&adults=2&children=0&child1=0&child2=0&child3=0&priceMin=0&priceMax=10000",
      },
      {
        text: "Bulgaaria",
        subPath:
          "/results?depart=TLL&arrive=BG&startDate=10-01-2024&endDate=10-01-2025&nightsMin=7&nightsMax=14&adults=2&children=0&child1=0&child2=0&child3=0&priceMin=0&priceMax=10000",
      },
      {
        text: "Itaalia",
        subPath:
          "/results?depart=TLL&arrive=IT&startDate=10-01-2024&endDate=10-01-2025&nightsMin=7&nightsMax=14&adults=2&children=0&child1=0&child2=0&child3=0&priceMin=0&priceMax=10000",
      },
      {
        text: "Kanaari saared",
        subPath:
          "/results?depart=TLL&arrive=CN&startDate=10-01-2024&endDate=10-01-2025&nightsMin=7&nightsMax=14&adults=2&children=0&child1=0&child2=0&child3=0&priceMin=0&priceMax=10000",
      },
      {
        text: "Kreeka",
        subPath:
          "/results?depart=TLL&arrive=GR&startDate=10-01-2024&endDate=10-01-2025&nightsMin=7&nightsMax=14&adults=2&children=0&child1=0&child2=0&child3=0&priceMin=0&priceMax=10000",
      },
      {
        text: "Araabia Ühendemiraadid",
        subPath:
          "/results?depart=TLL&arrive=AE&startDate=10-01-2024&endDate=10-01-2025&nightsMin=7&nightsMax=14&adults=2&children=0&child1=0&child2=0&child3=0&priceMin=0&priceMax=10000",
      },
      {
        text: "Hispaania",
        subPath:
          "/results?depart=TLL&arrive=ES&startDate=10-01-2024&endDate=10-01-2025&nightsMin=7&nightsMax=14&adults=2&children=0&child1=0&child2=0&child3=0&priceMin=0&priceMax=10000",
      },
      {
        text: "Tuneesia",
        subPath:
          "/results?depart=TLL&arrive=TN&startDate=10-01-2024&endDate=10-01-2025&nightsMin=7&nightsMax=14&adults=2&children=0&child1=0&child2=0&child3=0&priceMin=0&priceMax=10000",
      },
    ],
  },
  {
    text: "Teemareisid",
    isExpandable: true,
    subcategories: [
      {
        text: "Leia mängulust Legolandist",
        subPath: "/legoland",
      },
      {
        text: "Leia jõulutunne Lapimaalt",
        subPath: "/lapimaa",
      },
      {
        text: "Kevadkruiis Anne Veskiga",
        subPath: "/kevadkruiis",
      },
      {
        text: "Suvekruiisid Gotlandile",
        subPath: "/visby",
      },
      {
        text: "Etendus Rohelised niidud",
        subPath: "https://www.teatrireisid.ee/rohelisedniidud.html",
      },
      {
        text: "Etendus Kodoaknad ei unu",
        subPath: "https://www.teatrireisid.ee/kodoaknad.html",
      },
    ],
  },
  {
    text: "Klassireisid",
    isExpandable: false,
    path: "https://www.noortereisid.ee/",
  },
  {
    text: "Järelmaks",
    isExpandable: false,
    path: "/jarelmaks",
  },
  {
    text: "Kontakt",
    isExpandable: false,
    path: "/meist",
  },
];

export { navOptions };
export type { NavBarItem };
