import React, { FC, useState } from "react";
import logo from "../../assets/logoW.png";
import burger from "../../assets/icons/burger.svg";
import close from "../../assets/icons/close.svg";
import { navOptions } from "../../types/navbarTypes/mobileNav";
import { Link, useLocation } from "react-router-dom";
import arrowD from "../../assets/icons/arrowD.svg";
import mail from "../../assets/icons/mail.svg";
import phone from "../../assets/icons/phone.svg";

const NavBarSm: FC = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const openMenu = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  const [openItems, setOpenItems] = useState<string[]>([]);

  const handleToggle = (subPath: string) => {
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(subPath)) {
        return prevOpenItems.filter((path) => path !== subPath);
      } else {
        return [...prevOpenItems, subPath];
      }
    });
  };
  return (
    <div className="navbar-sm">
      <div className="navbar-sm__logo">
        {isOpen ? (
          ""
        ) : (
          <>
            <Link to={"/"}>
              <img src={logo} alt="" />
            </Link>
          </>
        )}
      </div>
      <div className="navbar-sm__menu" onClick={openMenu}>
        {isOpen ? (
          <img src={close} alt="" />
        ) : (
          <>
            <h6>Menüü</h6>
            <img src={burger} alt="" />
          </>
        )}
      </div>
      {isOpen && (
        <div className="open-menu">
          <div>
            <div className="open-menu__wrapper">
              <ul className="open-menu__list">
                {navOptions.map((link, index) => (
                  <li
                    key={`${link.path}_${index}`}
                    className={link.path === currentPath ? "current-page" : ""}
                  >
                    {link.isExpandable ? (
                      <div className="open-menu__item">
                        <div
                          className="open-menu__expand"
                          onClick={() =>
                            handleToggle(
                              link.isExpandable
                                ? `expandable_${index}`
                                : (link.path as string)
                            )
                          }
                        >
                          <h6>{link.text}</h6>
                          <img
                            src={arrowD}
                            alt=""
                            style={{
                              transform: openItems.includes(
                                link.isExpandable
                                  ? `expandable_${index}`
                                  : (link.path as string)
                              )
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            }}
                          />
                        </div>
                        {link.subcategories &&
                          openItems.includes(
                            link.isExpandable
                              ? `expandable_${index}`
                              : (link.path as string)
                          ) && (
                            <ul className="open-menu__sub">
                              {link.subcategories.map((subLink, subIndex) => (
                                <li
                                  key={`sub_${index}_${subIndex}`}
                                  className="list-item"
                                >
                                  <Link to={subLink.subPath} onClick={openMenu}>
                                    {subLink.text}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                      </div>
                    ) : (
                      <Link
                        to={link.path as string}
                        onClick={openMenu}
                        className="list-item"
                      >
                        {link.text}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>

              <div className="open-menu__contact">
                <div className="open-menu__contact--mail">
                  <a
                    href="mailto:info@reisihunt.ee"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}
                  >
                    <img src={mail} alt="" />
                    <p>info@reisihunt.ee</p>
                  </a>
                </div>
                <div className="open-menu__contact--phone">
                  <a
                    href="tel:+37258982838"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}
                  >
                    <img src={phone} alt="" />
                    <p>+372 5898 2838</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBarSm;
