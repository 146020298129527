import ResultsSingleView from "./components/ResultsComponents/ResultsSingleView";
import ResultsSingleViewSm from "./components/ResultsComponents/ResultsSingleViewSm";
import Contact from "./pages/Contact";
import Landing from "./pages/Landing";
import NewsletterPage from "./pages/NewsletterPage";
import Results from "./pages/Results";
import Gtk from "./pages/gtk";

const routes = [
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/results",
    element: <Results />,
  },
  {
    path: "/results/:hotelCode",
    element: <ResultsSingleView />,
  },
  {
    path: "/:routeId",
    element: <NewsletterPage />,
  },
];

export default routes;
