import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ResultsCard from "./ResultsCard";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ResultsTypes } from "../../types/pakkumisedTypes/offers";
import { useFilter } from "./ResultsFilterprovider";
import ResultsFilterSm from "./ResultsFilterSm";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import ResultsSortingSm from "./ResultsSortingSm";

interface FlightData {
  items: ResultsTypes[];
  f_minPrice: number;
  f_maxPrice: number;
  next: number;
}

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "rgba(183, 149, 197, 0.16)",
  },
  "&.Mui-selected": {
    backgroundColor: "rgba(183, 149, 197, 0.16)",
    "&:hover": {
      backgroundColor: "rgba(183, 149, 197, 0.16)",
    },
  },
}));

const ResultsView: FC = (): JSX.Element => {
  const location = useLocation();
  const { filters } = useFilter();
  const [nextPValue, setNextPValue] = useState<number>(2);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [filteredAndSortedItems, setFilteredAndSortedItems] = useState<
    ResultsTypes[]
  >([]);
  const CustomSelect = styled(Select)({
    borderRadius: "0.75rem",
    padding: "0.25rem 0.5rem",
    height: "2rem",
    border: "none",
  });
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [flightData, setFlightData] = useState<FlightData>({
    items: [],
    f_minPrice: 0,
    f_maxPrice: 0,
    next: 0,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;

  const getArriveFromQueryString = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("arrive");
  };
  const [sortedItems, setSortedItems] = useState<ResultsTypes[]>([]);
  const [selectedFilter, setSelectedFilter] =
    useState<string>("price-ascending");
  const fetchData = async (page: number) => {
    // Create a cancel token source
    const cancelTokenSource = axios.CancelToken.source();

    try {
      setFetchingData(true);
      const offset = (page - 1) * itemsPerPage;
      const apiUrl = `/api/reisid/offers${location.search}&offset=${offset}&limit=${itemsPerPage}`;

      // Pass the cancel token to the request configuration
      const response = await axios.get(apiUrl, {
        cancelToken: cancelTokenSource.token,
      });
      setFlightData(response.data);
    } catch (error) {
      // Check if the error is due to cancellation
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setFetchingData(false);
    }

    // Return the cancel token source for potential future use
    return cancelTokenSource;
  };

  const applyFilters = (data: ResultsTypes[]) => {
    let filteredData = data;

    if (filters?.hotelName) {
      filteredData = filteredData.filter(
        (item) => item.hotelName === filters.hotelName
      );
    }

    if (
      filters?.stars &&
      Array.isArray(filters.stars) &&
      filters.stars.length > 0
    ) {
      filteredData = filteredData.filter((item) =>
        filters.stars.includes(item.stars?.toString())
      );
    }

    if (filters?.board && filters.board.length > 0) {
      filteredData = filteredData.filter((item) =>
        filters.board.includes(item.board)
      );
    }
    if (filters?.price && filters.price.length === 2) {
      filteredData = filteredData.filter(
        (item) =>
          item.price >= filters.price[0] && item.price <= filters.price[1]
      );
    }
    return filteredData;
  };

  const filteredItems = applyFilters(flightData.items);
  const totalItemsCount = filteredItems.length;
  const totalPages = Math.ceil(totalItemsCount / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    const arrive = getArriveFromQueryString();

    if (arrive) {
      fetchData(currentPage);
    }
  }, [location.search]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const handlePageChange = async (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
    if (page === totalPages) {
      try {
        setFetchingData(true);
        const nextPage = flightData.next;
        setNextPValue((prevValue) => prevValue + 1);

        const currentPValue = nextPValue;

        const apiUrl = `/api/reisid/offers${location.search}&offset=${nextPage}&limit=${itemsPerPage}&p=${currentPValue}`;

        const response = await axios.get(apiUrl);
        const newData = response.data;
        setFlightData((prevFlightData) => ({
          items: [...prevFlightData.items, ...newData.items],
          f_minPrice: newData.f_minPrice,
          f_maxPrice: newData.f_maxPrice,
          next: newData.next,
          nextPValue: nextPValue, // Save the updated nextPValue
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetchingData(false);
      }
    }
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setSelectedFilter(event.target.value);
  };
  const generateSelectChangeEvent = (
    value: string
  ): React.ChangeEvent<{ value: unknown }> => {
    const event = {
      currentTarget: {
        value: value,
      },
      target: {
        value: value,
      },
    };
    return event as React.ChangeEvent<{ value: unknown }>;
  };

  const handleFilterChangeWrapper = (selectedFilter: string) => {
    const syntheticEvent = generateSelectChangeEvent(selectedFilter);
    handleFilterChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
  };

  function arraysEqual(a: any[], b: any[]): boolean {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }

    return true;
  }
  const paginatedItems = sortedItems.slice(startIndex, endIndex);
  useEffect(() => {
    const sortItems = (data: ResultsTypes[], sortOption: string) => {
      const sorted = [...data];

      if (sortOption === "price-ascending") {
        sorted.sort((a, b) => a.price - b.price);
      } else if (sortOption === "price-descending") {
        sorted.sort((a, b) => b.price - a.price);
      }

      return sorted;
    };

    const filteredItems = applyFilters(flightData.items);
    const sortedFilteredItems = sortItems(filteredItems, selectedFilter);

    if (
      !arraysEqual(
        sortedFilteredItems.map((item) => JSON.stringify(item)),
        filteredAndSortedItems.map((item) => JSON.stringify(item))
      )
    ) {
      setFilteredAndSortedItems(sortedFilteredItems);
    }
  }, [flightData.items, selectedFilter, applyFilters, filteredAndSortedItems]);

  return (
    <div className="results-view">
      <div
        className="results-view__header"
        style={{ justifyContent: fetchingData ? "center" : "space-between" }}
      >
        {!fetchingData ? (
          <>
            {filteredAndSortedItems.length > 0 && (
              <>
                <h3>Leitud {filteredItems.length} pakkumist</h3>
                <div className="results-view__filter">
                  <FormControl sx={{ border: "none" }}>
                    <Select
                      value={selectedFilter}
                      onChange={handleFilterChange}
                      MenuProps={{
                        classes: {
                          paper: "custom-menu-paper-class",
                          list: "custom-menu-list-class",
                        },
                        sx: {
                          "& .MuiMenuItem-root": {
                            fontWeight: "600",
                            "&:hover": {
                              backgroundColor: "rgba(183, 149, 197, 0.16)",
                            },
                          },
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: "rgba(183, 149, 197, 0.16)",
                            "&:hover": {
                              backgroundColor: "rgba(183, 149, 197, 0.16)",
                            },
                          },
                        },
                      }}
                      sx={{
                        letterSpacing: "0px",
                        borderRadius: "0.75rem",
                        padding: "0.25rem 0.5rem",
                        height: "2rem",
                        background: "#F5F5F5",
                        fontSize: "14px",
                        color: " var(--font-clr-secondary)",
                        fontWeight: "500",
                        border: "none",
                        backgroundColor: "#f5f5f5",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          borderWidth: 0,
                        },
                        "& .MuiMenu-paper": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                    >
                      <MenuItem
                        value="price-ascending"
                        sx={{ fontWeight: "600" }}
                      >
                        Hinna järgi kasvavalt
                      </MenuItem>
                      <MenuItem value="price-descending">
                        Hinna järgi kahanevalt
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </>
            )}
          </>
        ) : (
          <CircularProgress sx={{ color: "var(--clr-primary)" }} />
        )}
        {!fetchingData && (
          <>
            {windowWidth < 1024 && (
              <>
                <ResultsFilterSm applyFiltersFunction={applyFilters} />

                <ResultsSortingSm
                  selectedFilter={selectedFilter}
                  handleFilterChange={handleFilterChangeWrapper}
                />
              </>
            )}
          </>
        )}
      </div>

      {!fetchingData && (
        <div className="results-view__body">
          {filteredAndSortedItems.length > 0 ? (
            filteredAndSortedItems
              .slice(startIndex, endIndex)
              .map((item, index) => (
                <ResultsCard
                  hotelCode={item.hotelCode}
                  key={index}
                  location={item.location.et}
                  hotelName={item.hotelName}
                  checkinDate={item.checkinDate}
                  price={item.price}
                  nights={item.nights}
                  stars={item.stars}
                  thumbId={item.thumbId}
                  searchParams={location.search}
                  board={item.board}
                  ta_rating={item?.ta_rating}
                  google_rating={item?.google_rating}
                />
              ))
          ) : (
            <p>Tulemusi ei leitud.</p>
          )}
        </div>
      )}

      <div style={{ marginBottom: "80px" }}>
        {!fetchingData && (
          <div className="pagination-grid">
            <div></div>

            <>
              {filteredAndSortedItems.length > 0 && (
                <>
                  <Stack
                    spacing={2}
                    alignItems={"center"}
                    sx={{
                      "& .MuiPaginationItem-root.Mui-selected": {
                        backgroundColor: "transparent !important",
                        color: "var(--font-clr-secondary) !important",
                        fontWeight: 600,
                      },
                      "& .MuiPaginationItem-root": {
                        color: "var(--clr-primary) !important",
                        fontWeight: 600,
                      },
                      "& .MuiPaginationItem-previousNext": {
                        background: "#F5F5F5",
                        transition: "box-shadow 0.3s",
                        "&:hover": {
                          background: "#F5F5F5",
                          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                        },
                      },
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Stack>

                  <p>
                    Tulemused: {Math.min(startIndex + 1, totalItemsCount)}-
                    {totalItemsCount} / {totalItemsCount}
                  </p>
                </>
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultsView;
