import React, { FC, useEffect, useState } from "react";
import close from "../../assets/icons/closeGray.svg";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import { useFilter } from "./ResultsFilterprovider";
import axios from "axios";
import Link from "react-router-dom";

const mockFoodData = [
  { name: "Ultra kõik hinnas", label: "UAI" },
  { name: "Kõik hinnas", label: "AI" },
  { name: "Hommiku- ja õhtusöögiga", label: "HB" },
  { name: "Hommikusöögiga", label: "BB" },
  { name: "Ainult tuba", label: "RO" },
];
const mockStars = [
  { name: "★★★★★", label: "5" },
  { name: "★★★★", label: "4" },
  { name: "★★★", label: "3" },
  { name: "★★", label: "2" },
];

const Filter: FC = (): JSX.Element => {
  const [value, setValue] = useState<[number, number]>([300, 10000]);
  const { filters, updateFilters, clearFilters } = useFilter();
  const [filterValues, setFilterValues] = useState<{
    hotelName: string;
    stars: string[];
    board: string[];
    price: number[];
  }>({
    hotelName: "",
    stars: [],
    board: [],
    price: [300, 10000],
  });
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<any[]>([]);

  const handleLocationChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputText = event.target.value;

    setFilterValues({ ...filterValues, hotelName: inputText });

    if (inputText.length >= 3) {
      try {
        const response = await axios.get(
          `/api/hotels/search/${inputText.substring(
            0,
            3
          )}`
        );
        const filteredSuggestions = response.data.filter((suggestion: any) =>
          suggestion.name.toLowerCase().includes(inputText.toLowerCase())
        );

        if (filteredSuggestions.length === 0) {
          setSuggestions([
            {
              id: -1,
            },
          ]);
        } else {
          setSuggestions(filteredSuggestions);
        }
      } catch (error) {
        console.error("Error fetching hotel suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    const updatedValue = newValue as number[];
    setValue([updatedValue[0], updatedValue[1]]);
    setFilterValues({
      ...filterValues,
      price: [updatedValue[0], updatedValue[1]],
    });
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filter = event.target.name;
    const isChecked = event.target.checked;
    const index = activeFilters.indexOf(filter);

    const selectedStarLabel = mockStars.find(
      (star) => star.name === filter
    )?.label;
    const selectedFoodLabel = mockFoodData.find(
      (food) => food.name === filter
    )?.label;

    if (isChecked && index === -1) {
      setActiveFilters([...activeFilters, filter]);
      if (selectedStarLabel) {
        const updatedStars = [
          ...(filterValues.stars as string[]),
          selectedStarLabel,
        ];
        setFilterValues({ ...filterValues, stars: updatedStars });
      }
      if (selectedFoodLabel) {
        const updatedFoodFilters = [
          ...(filterValues.board as string[]),
          selectedFoodLabel,
        ];
        setFilterValues({ ...filterValues, board: updatedFoodFilters });
      }
    } else if (!isChecked && index !== -1) {
      const updatedFilters = [...activeFilters];
      updatedFilters.splice(index, 1);
      setActiveFilters(updatedFilters);

      if (selectedStarLabel) {
        const updatedStars = filterValues.stars.filter(
          (star: string) => star !== selectedStarLabel
        );
        setFilterValues({ ...filterValues, stars: updatedStars });
      }
      if (selectedFoodLabel) {
        const updatedFoodFilters = filterValues.board.filter(
          (food: string) => food !== selectedFoodLabel
        );
        setFilterValues({ ...filterValues, board: updatedFoodFilters });
      }
    }
  };

  const applyFilters = () => {
    updateFilters(filterValues);
  };

  const clearAllFilters = () => {
    setFilterValues({
      hotelName: "",
      stars: [],
      board: [],
      price: [300, 10000],
    });
    setActiveFilters([]);
  };
  const convertFiltersToLabels = (filters: string[]) => {
    const labels: string[] = [];
    filters.forEach((filter) => {
      const selectedStarLabel = mockStars.find(
        (star) => star.name === filter
      )?.label;
      const selectedFoodLabel = mockFoodData.find(
        (food) => food.name === filter
      )?.label;
      if (selectedStarLabel) {
        labels.push(selectedStarLabel);
      }
      if (selectedFoodLabel) {
        labels.push(selectedFoodLabel);
      }
    });
    return labels;
  };

  const removeFilter = (filterToRemove: string) => {
    const updatedFilters = activeFilters.filter(
      (filter) => filter !== filterToRemove
    );
    setActiveFilters(updatedFilters);

    const labels = convertFiltersToLabels([filterToRemove]);

    labels.forEach((label) => {
      const updatedStars = filterValues.stars.filter(
        (star: string) => star !== label
      );
      const updatedBoard = filterValues.board.filter(
        (board: string) => board !== label
      );

      setFilterValues({
        ...filterValues,
        stars: updatedStars,
        board: updatedBoard,
      });
    });
  };
  useEffect(() => {
    setActiveFilters(activeFilters);
  }, [activeFilters]);
  const searchParams = new URLSearchParams(window.location.search);

  return (
    <div className="filter">
      <div className="filter__title">
        <h3>Filtreeri</h3>
      </div>
      <div className="modal-large__active">
        <h4>Aktiivsed filtrid</h4>
        {activeFilters.length > 0 ? (
          <>
            <div className="modal-large__active--filters">
              {activeFilters.map((filter, index) => (
                <span key={index}>
                  {filter}{" "}
                  <img
                    src={close}
                    onClick={() => removeFilter(filter)}
                    alt=""
                  />
                </span>
              ))}
            </div>
            <button onClick={clearAllFilters}>Tühjenda kõik</button>
          </>
        ) : (
          <small>Puuduvad</small>
        )}
      </div>
      <div className="filter__search">
        <h4>Otsi hotelli</h4>
        <input
          type="text"
          value={filterValues.hotelName}
          onChange={handleLocationChange}
          placeholder="Sisesta hotelli nimi"
        />
        {suggestions.length > 0 && (
          <ul>
            {suggestions.map((suggestion, index) =>
              suggestion.id !== -1 ? (
                <li key={index}>
                  <a
                    href={`/results/${
                      suggestion.id
                    }?${searchParams.toString()}`}
                  >
                    {suggestion.name}
                  </a>
                </li>
              ) : (
                <li key={index}>Ühtegi hotelli ei leitud.</li>
              )
            )}
          </ul>
        )}
      </div>
      {/* <div className="filter__city">
            <h4>Linn</h4>
            <div className="filter__city--check">
              <FormGroup style={{ marginTop: "0.5rem" }}>
                <FormControlLabel
                  control={<Checkbox onChange={handleFilterChange} name="Kemer" />}
                  label="Kemer"
                />
                <FormControlLabel
                  control={
                    <Checkbox onChange={handleFilterChange} name="Beldibi" />
                  }
                  label="Beldibi"
                />
                <FormControlLabel
                  control={<Checkbox onChange={handleFilterChange} name="Side" />}
                  label="Side"
                />
                <FormControlLabel
                  control={<Checkbox onChange={handleFilterChange} name="Kiris" />}
                  label="Kiris"
                />
                <FormControlLabel
                  control={<Checkbox onChange={handleFilterChange} name="Alanya" />}
                  label="Alanya"
                />
                <FormControlLabel
                  control={
                    <Checkbox onChange={handleFilterChange} name="Manavgat" />
                  }
                  label="Manavgat"
                />
              </FormGroup>
            </div>
          </div> */}
      <div className="filter__food">
        <h4>Toitlustus</h4>
        <div className="filter__city--check">
          <FormGroup style={{ marginTop: "0.5rem" }}>
            {mockFoodData.map((item) => (
              <FormControlLabel
                key={item.name}
                sx={{
                  "& .MuiFormControlLabel-label ": {
                    color: "#645F6C",
                    fontSize: "0.875rem",
                    fontFamily: "Lato",
                    fontWeight: "600",
                  },
                  ".MuiCheckbox-colorPrimary": {
                    color: "#b795c5",
                  },

                  ".Mui-checked": {
                    color: "var(--clr-primary) !important",
                  },
                  ".MuiSvgfIcon-root": {
                    height: "1.125rem",
                    width: "1.125rem",
                  },
                  ".MuiFormControlLabel-label:hover": {
                    color: "#c552e1",
                  },
                }}
                control={
                  <Checkbox
                    onChange={handleFilterChange}
                    name={item.name}
                    checked={activeFilters.includes(item.name)}
                    sx={{
                      "&:hover": {
                        background: "#F5F5F5",
                      },
                      "&:active": {
                        background: "rgba(103, 80, 161, 0.12);",
                      },
                    }}
                  />
                }
                label={item.name}
                classes={{
                  root: activeFilters.includes(item.name)
                    ? "checked"
                    : "unchecked",
                }}
              />
            ))}
          </FormGroup>
        </div>
      </div>
      <div className="filter__price">
        <h4>Hind inimese kohta</h4>
        <p>{`${value[0]} - ${value[1]} €`}</p>
        <div className="filter__price--slider">
          <Slider
            value={value}
            onChange={handleChange}
            color="primary"
            min={300}
            max={10000}
            step={10}
            sx={{
              width: 300,
              color: "#645F6C",
              "&:hover": {
                color: "var(--clr-primary)",
              },
            }}
          />
        </div>
        <div className="filter__price--input">
          <input
            type="text"
            placeholder="300"
            value={value[0]}
            onChange={(e) => setValue([parseInt(e.target.value), value[1]])}
          />
          <p>kuni</p>
          <input
            type="text"
            placeholder="10 000"
            value={value[1]}
            onChange={(e) => setValue([value[0], parseInt(e.target.value)])}
          />
        </div>
      </div>
      {/* <div className="filter__selection">
        <h4>Valikukriteeriumid</h4>
        <div className="filter__selection--check">
          <FormGroup style={{ marginTop: "0.5rem" }}>
            <FormControlLabel control={<Checkbox />} label="TOP hotellid" />
            <FormControlLabel control={<Checkbox />} label="Lastesõbralik" />
            <FormControlLabel
              control={<Checkbox />}
              label="Hommiku- ja õhtusöögiga"
            />
            <FormControlLabel control={<Checkbox />} label="Paaride lemmik" />
            <FormControlLabel
              control={<Checkbox />}
              label="Ainult täiskasvanutele"
            />
          </FormGroup>
        </div>
      </div> */}
      <div className="filter__stars">
        <h4>Hotelli kategooria</h4>
        <div className="filter__stars--check">
          <FormGroup style={{ marginTop: "0.5rem" }}>
            {mockStars.map((item) => (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label ": {
                    color: "var(--clr-primary)",
                  },
                  ".Mui-checked": {
                    color: "var(--clr-primary) !important",
                  },
                }}
                key={item.name}
                control={
                  <Checkbox
                    name={item.name}
                    style={{
                      color: "#b795c5",
                    }}
                    sx={{
                      "&:hover": {
                        background: "#F5F5F5",
                      },
                      "&:active": {
                        background: "rgba(103, 80, 161, 0.12);",
                      },
                    }}
                    checked={activeFilters.includes(item.name)}
                    onChange={handleFilterChange}
                  />
                }
                label={item.name}
                classes={{
                  root: activeFilters.includes(item.name)
                    ? "checked"
                    : "unchecked",
                }}
              />
            ))}
          </FormGroup>
        </div>
      </div>
      {/* <div className="filter__far">
        <h4>Hotelli kaugus lennujaamast</h4>
        <div className="filter__far--check">
          <RadioGroup style={{ marginTop: "0.5rem" }}>
            <FormControlLabel
              value="40"
              control={<Radio />}
              label="kuni 40 km"
            />
            <FormControlLabel
              value="80"
              control={<Radio />}
              label="kuni 80 km"
            />
            <FormControlLabel
              value="120"
              control={<Radio />}
              label="kuni 120 km"
            />
            <FormControlLabel
              value="160"
              control={<Radio />}
              label="kuni 160 km"
            />
            <FormControlLabel
              value="more"
              control={<Radio />}
              label="rohkem kui 160 km"
            />
          </RadioGroup>
        </div>
      </div>
      <div className="filter__from-center">
        <h4>Hotelli kaugus keskusest</h4>
        <div className="filter__from-center--check">
          <RadioGroup style={{ marginTop: "0.5rem" }}>
            <FormControlLabel value="1" control={<Radio />} label="kuni 1 km" />
            <FormControlLabel value="5" control={<Radio />} label="kuni 5 km" />
            <FormControlLabel
              value="15"
              control={<Radio />}
              label="kuni 15 km"
            />
            <FormControlLabel
              value="20"
              control={<Radio />}
              label="kuni 20 km"
            />
            <FormControlLabel
              value="more20"
              control={<Radio />}
              label="rohkem kui 20 km"
            />
          </RadioGroup>
        </div>
      </div>
      <div className="filter__from-beach">
        <h4>Hotelli kaugus rannast</h4>
        <div className="filter__from-beach--check">
          <RadioGroup style={{ marginTop: "0.5rem" }}>
            <FormControlLabel
              value="100m"
              control={<Radio />}
              label="kuni 100 m"
            />
            <FormControlLabel
              value="500m"
              control={<Radio />}
              label="kuni 500 m"
            />
            <FormControlLabel
              value="1km"
              control={<Radio />}
              label="kuni 1 km"
            />
            <FormControlLabel
              value="2km"
              control={<Radio />}
              label="kuni 2 km"
            />

            <FormControlLabel
              value="more2"
              control={<Radio />}
              label="rohkem kui 2 km"
            />
          </RadioGroup>
        </div>
      </div> */}
      <div className="filter__button">
        <button className="btn-secondary btn" onClick={applyFilters}>
          Filtreeri
        </button>
      </div>
    </div>
  );
};

export default Filter;
