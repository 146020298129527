import React, { FC, useEffect, useState } from "react";
import filter from "../../assets/icons/filter.svg";

import close from "../../assets/icons/closeGray.svg";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import { useFilter } from "./ResultsFilterprovider";
import axios from "axios";

const mockData = [
  { name: "Kemer", label: "Kemer" },
  { name: "Manavgat", label: "Manavgat" },
  { name: "Cairo", label: "Cairo" },
];

const mockFoodData = [
  { name: "Ultra kõik hinnas", label: "UAI" },
  { name: "Kõik hinnas", label: "AI" },
  { name: "Hommiku- ja õhtusöögiga", label: "HB" },
  { name: "Hommikusöögiga", label: "BB" },
  { name: "Ainult tuba", label: "RO" },
];

const mockOption = [
  { name: "TOP hotellid", label: "TOP hotellid" },
  { name: "Lastesõbralik", label: "Lastesõbralik" },
  { name: "Paaride lemmik", label: "Paaride lemmik" },
  { name: "Ainult täiskasvanutele", label: "Ainult täiskasvanutele" },
];

const mockAirport = [
  { name: "kuni 40 km", label: "kuni 40 km" },
  { name: "kuni 80 km", label: "kuni 80 km" },
  { name: "kuni 120 km", label: "kuni 120 km" },
  { name: "kuni 160 km", label: "kuni 160 km" },
  { name: "rohkem kui 160 km", label: "rohkem kui 160 km" },
];
const mockCenter = [
  { name: "kuni 1 km", label: "kuni 1 km" },
  { name: "kuni 5 km", label: "kuni 5 km" },
  { name: "kuni 15 km", label: "kuni 15 km" },
  { name: "kuni 20 km", label: "kuni 20 km" },
  { name: "rohkem kui 20 km", label: "rohkem kui 20 km" },
];

const mockBeach = [
  { name: "kuni 100m", label: "kuni 100m" },
  { name: "kuni 500m", label: "kuni 500m" },
  { name: "kuni 1 km", label: "kuni 1 km" },
  { name: "kuni 2 km", label: "kuni 2 km" },
  { name: "rohkem kui 2 km", label: "rohkem kui 2 km" },
];
const mockStars = [
  { name: "★★★★★", label: "5" },
  { name: "★★★★", label: "4" },
  { name: "★★★", label: "3" },
  { name: "★★", label: "2" },
];
interface ResultsFilterSmProps {
  applyFiltersFunction: (filters: any) => void;
}
const ResultsFilterSm: FC<ResultsFilterSmProps> = ({
  applyFiltersFunction,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState([300, 10000]);
  const [selectedRadioFilter, setSelectedRadioFilter] = useState<string>("");
  const toggleRadioFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadioFilter(event.target.value);
  };
  const [selectedRadioFilters, setSelectedRadioFilters] = useState<{
    [key: string]: string;
  }>({
    airport: "",
    center: "",
  });
  const { updateFilters, clearFilters, removeFilter } = useFilter();
  const [filterValues, setFilterValues] = useState<{
    hotelName: string;
    stars: string[];
    board: string[];
    price?: number[];
  }>({
    hotelName: "",
    stars: [],
    board: [],
    price: [300, 10000],
  });
  const handleChange = (event: Event, newValue: number | number[]) => {
    const updatedValue = newValue as number[];
    setValue([updatedValue[0], updatedValue[1]]);
    setFilterValues({
      ...filterValues,
      price: [updatedValue[0], updatedValue[1]],
    });
  };
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<any[]>([]);

  const handleLocationChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputText = event.target.value;

    setFilterValues({ ...filterValues, hotelName: inputText });

    if (inputText.length >= 3) {
      try {
        const response = await axios.get(
          `/api/hotels/search/${inputText.substring(0, 3)}`
        );
        const filteredSuggestions = response.data.filter((suggestion: any) =>
          suggestion.name.toLowerCase().includes(inputText.toLowerCase())
        );

        if (filteredSuggestions.length === 0) {
          setSuggestions([
            {
              id: -1,
            },
          ]);
        } else {
          setSuggestions(filteredSuggestions);
        }
      } catch (error) {
        console.error("Error fetching hotel suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };
  const toggleFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filter = event.target.name;
    const isChecked = event.target.checked;
    const index = selectedFilters.indexOf(filter);

    const selectedStarLabel = mockStars.find(
      (star) => star.name === filter
    )?.label;
    const selectedFoodLabel = mockFoodData.find(
      (food) => food.name === filter
    )?.label;

    if (isChecked && index === -1) {
      setSelectedFilters([...selectedFilters, filter]);
      if (selectedStarLabel) {
        const updatedStars = [
          ...(filterValues.stars as string[]),
          selectedStarLabel,
        ];
        setFilterValues({ ...filterValues, stars: updatedStars });
      }
      if (selectedFoodLabel) {
        const updatedFoodFilters = [
          ...(filterValues.board as string[]),
          selectedFoodLabel,
        ];
        setFilterValues({ ...filterValues, board: updatedFoodFilters });
      }
    } else if (!isChecked && index !== -1) {
      const updatedFilters = [...selectedFilters];
      updatedFilters.splice(index, 1);
      setSelectedFilters(updatedFilters);

      if (selectedStarLabel) {
        const updatedStars = filterValues.stars.filter(
          (star: string) => star !== selectedStarLabel
        );
        setFilterValues({ ...filterValues, stars: updatedStars });
      }
      if (selectedFoodLabel) {
        const updatedFoodFilters = filterValues.board.filter(
          (food: string) => food !== selectedFoodLabel
        );
        setFilterValues({ ...filterValues, board: updatedFoodFilters });
      }
    }
  };
  const filterOpen = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };
  const applyFilters = () => {
    updateFilters(filterValues);
    filterOpen();
  };
  const handleRemoveFilter = (filterToRemove: string) => {
    const updatedFilters = selectedFilters.filter(
      (filter) => filter !== filterToRemove
    );
    setSelectedFilters(updatedFilters);

    const selectedStarLabel = mockStars.find(
      (star) => star.label === filterToRemove
    );
    const selectedFoodLabel = mockFoodData.find(
      (food) => food.label === filterToRemove
    );

    let updatedStars = [...filterValues.stars];
    let updatedBoard = [...filterValues.board];

    if (selectedStarLabel) {
      updatedStars = updatedStars.filter(
        (star: string) => star !== selectedStarLabel.label
      );
    }

    if (selectedFoodLabel) {
      updatedBoard = updatedBoard.filter(
        (food: string) => food !== selectedFoodLabel.label
      );
    }

    setFilterValues({
      hotelName: filterValues.hotelName,
      stars: updatedStars,
      board: updatedBoard,
    });
    removeFilter(filterToRemove);
  };

  const handleClearClick = () => {
    setSelectedRadioFilter("");
    setFilterValues({
      hotelName: "",
      stars: [],
      board: [],
      price: [300, 10000],
    });
    clearFilters(clearFilters);
    setSelectedFilters([]);
  };
  const searchParams = new URLSearchParams(window.location.search);

  return (
    <div className="filter-sm">
      <div className="filter-sm__option">
        <div onClick={filterOpen} style={{ display: "flex" }}>
          <img src={filter} alt="" />
          <p>Filtreeri tulemusi</p>
        </div>
      </div>
      <div className="filter-sm__selected">
        <h5>Määratud filtrid:</h5>
        <div className="filter-sm__selected--options">
          {selectedFilters.length > 0 ? (
            <>
              <div className="modal-large__active--filters">
                {selectedFilters.map((filter, index) => (
                  <span key={index}>
                    {filter}{" "}
                    <img
                      src={close}
                      onClick={() => handleRemoveFilter(filter)}
                      alt=""
                    />
                  </span>
                ))}
              </div>
              <div className="filter-sm__selected--empty">
                <button onClick={handleClearClick}>Tühjeda kõik</button>
              </div>
            </>
          ) : (
            <small>Puuduvad</small>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="modal-large">
          <div className="filter-scroll">
            <div className="modal-large__header">
              <div className="modal-large__header--img">
                <img src={close} alt="" onClick={filterOpen} />
              </div>
              <div className="modal-large__header--title">
                <p>filtreeri:</p>
              </div>
            </div>
            <div className="modal-large__active">
              <p>Aktiivsed filtrid</p>
              {selectedFilters.length > 0 || selectedRadioFilter ? (
                <>
                  <div className="modal-large__active--filters">
                    {selectedFilters.map((filter, index) => (
                      <span key={index}>
                        {filter}{" "}
                        <img
                          src={close}
                          onClick={() => handleRemoveFilter(filter)}
                          alt=""
                        />
                      </span>
                    ))}
                    {selectedRadioFilter && (
                      <span>
                        {selectedRadioFilter} <img src={close} alt="" />
                      </span>
                    )}
                  </div>
                  <button onClick={handleClearClick}>Tühjenda kõik</button>
                </>
              ) : (
                <small>Puuduvad</small>
              )}
            </div>
            <div className="modal-large__search">
              <div className="filter__search">
                <input
                  type="text"
                  value={filterValues.hotelName}
                  onChange={handleLocationChange}
                  placeholder="Sisesta hotelli nimi"
                />
                {suggestions.length > 0 && (
                  <ul style={{ height: "500px", maxHeight: "300px" }}>
                    {suggestions.map((suggestion, index) =>
                      suggestion.id !== -1 ? (
                        <li key={index}>
                          <a
                            href={`/results/${
                              suggestion.id
                            }?${searchParams.toString()}`}
                            onClick={filterOpen}
                          >
                            {suggestion.name}
                          </a>
                        </li>
                      ) : (
                        <li key={index}>Ühtegi hotelli ei leitud.</li>
                      )
                    )}
                  </ul>
                )}
              </div>
            </div>
            {/* <div className="modal-large__city">
            <h4>Linn</h4>
            <FormGroup>
              {mockData.map((item) => (
                <FormControlLabel
                  key={item.name}
                  control={
                    <Checkbox
                      name={item.name}
                      checked={selectedFilters.includes(item.name)}
                      onChange={toggleFilters}
                    />
                  }
                  label={item.label}
                  classes={{
                    root: selectedFilters.includes(item.name)
                      ? "checked"
                      : "unchecked",
                  }}
                />
              ))}
            </FormGroup>
          </div> */}
            <div className="modal-large__pack">
              <h4>Toitlustus</h4>
              <FormGroup>
                {mockFoodData.map((item) => (
                  <FormControlLabel
                    key={item.name}
                    sx={{
                      "& .MuiFormControlLabel-label ": {
                        color: "#645F6C",
                        fontSize: "0.875rem",
                        fontFamily: "Lato",
                        fontWeight: "600",
                      },
                      ".MuiCheckbox-colorPrimary": {
                        color: "#b795c5",
                      },

                      ".Mui-checked": {
                        color: "var(--clr-primary) !important",
                      },
                    }}
                    control={
                      <Checkbox
                        name={item.name}
                        checked={selectedFilters.includes(item.name)}
                        onChange={toggleFilters}
                        sx={{
                          marginLeft: "0.5rem",
                        }}
                      />
                    }
                    label={item.name}
                    classes={{
                      root: selectedFilters.includes(item.name)
                        ? "checked"
                        : "unchecked",
                    }}
                  />
                ))}
              </FormGroup>
            </div>
            <div className="modal-large__price">
              <h4>Hind inimese kohta</h4>
              <p>{`${value[0]} - ${value[1]} €`}</p>
              <div className="filter__price--slider">
                <Slider
                  value={value}
                  onChange={handleChange}
                  color="primary"
                  min={300}
                  max={10000}
                  step={10}
                  sx={{ width: 300, color: "var(--clr-primary)" }}
                />
              </div>
              <div className="filter__price--input">
                <input
                  type="text"
                  placeholder="300"
                  value={value[0]}
                  onChange={(e) =>
                    setValue([parseInt(e.target.value), value[1]])
                  }
                />
                <p>kuni</p>
                <input
                  type="text"
                  placeholder="10 000"
                  value={value[1]}
                  onChange={(e) =>
                    setValue([value[0], parseInt(e.target.value)])
                  }
                />
              </div>
            </div>
            {/* <div className="modal-large__city">
            <h4>Valikukriteeriumid</h4>
            <FormGroup>
              {mockOption.map((item) => (
                <FormControlLabel
                  key={item.name}
                  control={
                    <Checkbox
                      name={item.name}
                      checked={selectedFilters.includes(item.name)}
                      onChange={toggleFilters}
                    />
                  }
                  label={item.label}
                  classes={{
                    root: selectedFilters.includes(item.name)
                      ? "checked"
                      : "unchecked",
                  }}
                />
              ))}
            </FormGroup>
          </div> */}
            <div className="modal-large__stars">
              <h4>Hotelli kategooria</h4>
              <div className="filter__stars--check">
                {mockStars.map((item: any) => (
                  <FormControlLabel
                    key={item.name}
                    control={
                      <Checkbox
                        name={item.name}
                        checked={selectedFilters.includes(item.name)}
                        onChange={toggleFilters}
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label ": {
                        color: "var(--clr-primary)",
                        fontSize: "0.875rem",
                        fontFamily: "Lato",
                        fontWeight: "600",
                      },
                      ".MuiCheckbox-colorPrimary": {
                        color: "#b795c5",
                      },

                      ".Mui-checked": {
                        color: "var(--clr-primary) !important",
                      },
                    }}
                    label={item.name}
                    classes={{
                      root: selectedFilters.includes(item.name)
                        ? "checked"
                        : "unchecked",
                    }}
                  />
                ))}
              </div>
            </div>
            {/* <div className="modal-large__far">
            <h4>Hotelli kaugus lennujaamast</h4>
            <div className="filter__far--check">
              <RadioGroup
                value={selectedRadioFilter}
                onChange={toggleRadioFilter}
              >
                {mockAirport.map((item) => (
                  <FormControlLabel
                    key={item.name}
                    value={item.name}
                    control={<Radio />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>
          <div className="modal-large__center">
            <h4>Hotelli kaugus keskusest</h4>
            <div className="filter__far--check">
              <RadioGroup
                value={selectedRadioFilter}
                onChange={toggleRadioFilter}
              >
                {mockCenter.map((item) => (
                  <FormControlLabel
                    key={item.name}
                    value={item.name}
                    control={<Radio />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>
          <div className="modal-large__beach">
            <h4>Hotelli kaugus rannast</h4>
            <div className="filter__far--check">
              <RadioGroup
                value={selectedRadioFilter}
                onChange={toggleRadioFilter}
              >
                {mockBeach.map((item) => (
                  <FormControlLabel
                    key={item.name}
                    value={item.name}
                    control={<Radio />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </div>
          </div> */}
          </div>
          <div className="modal-large__buttons">
            <button
              onClick={filterOpen}
              className="modal-large__buttons--close"
            >
              Sulge
            </button>
            <button onClick={applyFilters} className="btn-secondary btn">
              Filtreeri
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default ResultsFilterSm;
