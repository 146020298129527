export interface IFrameConfig {
  route: string;
  iframeSrc: string;
}

const iframeConfig: IFrameConfig[] = [
  {
    route: "/uudiskiri",
    iframeSrc: "https://www.noortereisid.ee/uudiskiri.html",
  },
  {
    route: "/legoland",
    iframeSrc: "https://www.noortereisid.ee/legoland",
  },
  {
    route: "/lapimaa",
    iframeSrc: "https://www.noortereisid.ee/lapimaa",
  },
  {
    route: "/kevadkruiis",
    iframeSrc: "https://www.noortereisid.ee/kevadkruiis",
  },
  {
    route: "/visby",
    iframeSrc: "https://www.noortereisid.ee/visby",
  },
  {
    route: "/meist",
    iframeSrc: "https://www.noortereisid.ee/meist",
  },
  {
    route: "/heateada",
    iframeSrc: "https://www.noortereisid.ee/hea-teada.html",
  },  
  {
    route: "/jarelmaks",
    iframeSrc: "https://www.noortereisid.ee/jarelmaks.html",
  },
];

export default iframeConfig;
