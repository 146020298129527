import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logoW.png";
import logoD from "../../assets/logoD.png";

import mail from "../../assets/icons/mail.svg";
import mailP from "../../assets/icons/mailP.svg";
import phoneP from "../../assets/icons/phoneP.svg";

import phone from "../../assets/icons/phone.svg";
import { navOptions, NavBarItem } from "../../types/navbarTypes/mobileNav";

const NavBar: FC = (): JSX.Element => {
  const location = useLocation();
  const [scrolling, setScrolling] = useState<boolean>(false);
  const [expandedDropdown, setExpandedDropdown] = useState<string | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 50;

      if (scrollPosition > threshold) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleFocus = (text: string) => {
    setExpandedDropdown(text);
  };

  const handleBlur = () => {
    setExpandedDropdown(null);
  };

  return (
    <div className={`navbar ${scrolling ? "scrolling" : ""}`}>
      <div className="navbar__wrapper">
        <div className="navbar__logo">
          <Link to={"/"}>
            {scrolling ? (
              <img src={logoD} alt="logo" />
            ) : (
              <img src={logo} alt="logo" />
            )}
          </Link>
        </div>
        <div className="navbar__list">
          <ul>
            {navOptions.map((link) => (
              <React.Fragment key={link.text}>
                {link.isExpandable ? (
                  <li
                    className="expandable-item"
                    onMouseEnter={() => handleFocus(link.text)}
                    onMouseLeave={handleBlur}
                  >
                    <div className="expandable-header" tabIndex={0}>
                      <span>{link.text}</span>
                    </div>

                    <ul className="subcategories">
                      {link.subcategories && (
                        <>
                          <ul className="subcategories-grid">
                            {link.subcategories
                              .slice(0, 5)
                              .map((subcategory) => (
                                <Link
                                  key={subcategory.text}
                                  to={subcategory.subPath}
                                >
                                  <li>{subcategory.text}</li>
                                </Link>
                              ))}
                          </ul>
                          <ul className="subcategories-grid last">
                            {link.subcategories.slice(5).map((subcategory) => (
                              <Link
                                key={subcategory.text}
                                to={subcategory.subPath}
                              >
                                <li>{subcategory.text}</li>
                              </Link>
                            ))}
                          </ul>
                        </>
                      )}
                    </ul>
                  </li>
                ) : (
                  <Link to={link.path as any}>
                    <li
                      className={`list-item ${
                        link.path === location.pathname ? "current-page" : ""
                      }`}
                    >
                      {link.text}
                    </li>
                  </Link>
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
        <div className="navbar__contact">
          <ul>
            <li>
              {scrolling ? (
                <div
                  className="list-item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <img src={mailP} alt="x" />
                  <a
                    href="mailto:info@reisihunt.ee"
                    style={{ color: "#645f6c" }}
                  >
                    info@reisihunt.ee
                  </a>
                </div>
              ) : (
                <div
                  className="list-item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <img src={mail} alt="x" />
                  <a href="mailto:info@reisihunt.ee" style={{ color: "#fff" }}>
                    info@reisihunt.ee
                  </a>
                </div>
              )}
            </li>
            <li>
              {scrolling ? (
                <div
                  className="list-item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <img src={phoneP} alt="x" />
                  <a href="tel:+37258982838" style={{ color: "#645f6c" }}>
                    +372 5898 2838
                  </a>
                </div>
              ) : (
                <div
                  className="list-item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <img src={phone} alt="x" />
                  <a href="tel:+37258982838" style={{ color: "#fff" }}>
                    +372 5898 2838
                  </a>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
