import React, { FC } from "react";
import img from "../../assets/multiImg.png";
import arrow from "../../assets/icons/arrowR.svg";
import { Link } from "react-router-dom";

const Newsletter: FC = (): JSX.Element => {
  return (
    <div className="newsletter">
      <div className="newsletter__grid">
        <div className="newsletter__info">
          <h1>Ole esimene!</h1>
          <p>
            Liitu Reisihundi perega ja saa osa sooduspakkumistest,
            reisisoovitustest ja tähtsamatest reisimaailma uudistest.
          </p>
          <Link to={"/uudiskiri"}>
            <button className="btn">
              Liitu uudiskirjaga <img src={arrow} alt="" />
            </button>
          </Link>
        </div>
        <div className="newsletter__image">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
