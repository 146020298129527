// NewsletterPage.tsx

import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import iframeConfig, { IFrameConfig } from "../types/iframeConfig";

const findIframeConfig = (route: string): IFrameConfig | undefined => {
  return iframeConfig.find((config) => config.route === route);
};

const NewsletterPage: FC = (): JSX.Element => {
  const { routeId } = useParams<{ routeId: string }>();
  const iframeConfig = findIframeConfig(`/${routeId}`);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (!iframeConfig) {
    return <div>Leht pole saadaval.</div>;
  }

  const { iframeSrc } = iframeConfig;

  return (
    <div className="news" style={{ paddingTop: "5rem" }}>
      <div className="news__wrapper">
        <iframe src={iframeSrc} width="100%" height="900" />
      </div>
    </div>
  );
};

export default NewsletterPage;
