import lego from "../assets/legoland.png";
import lapimaa from "../assets/lapimaa.jpg";
import kevad from "../assets/kevadkruiis.png";
import gotland from "../assets/gotland.jpeg";
import teater from "../assets/teater.jpg";

export interface Campaign {
  imageUrl: string;
  text: string;
  title: string;
  link: string;
}

const mockCampaigns: Campaign[] = [
  {
    imageUrl: lego,
    text: "Alates 550 €",
    title: "Leia mängulust Legolandist",
    link: "/legoland",
  },
  {
    imageUrl: lapimaa,
    text: "Alates 420 €",
    title: "Leia jõulutunne Lapimaalt",
    link: "/lapimaa",
  },
  {
    imageUrl: kevad,
    text: "Alates 46 €",
    title: "Kevadkruiis Anne Veskiga",
    link: "/kevadkruiis",
  },
  {
    imageUrl: gotland,
    text: "Alates 75 €",
    title: "Suvekruiisid Gotlandile",
    link: "/visby",
  },
  {
    imageUrl: teater,
    text: "Valikus esietendused",
    title: "Meeleolukad teatrireisid",
    link: "https://www.teatrireisid.ee/",
  },
];

export default mockCampaigns;
