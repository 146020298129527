import React, {
  createContext,
  FC,
  useContext,
  useState,
  ReactNode,
} from "react";

export const FilterContext = createContext<any>(null);

export const FilterProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const initialFilters = {
    hotelName: "",
    stars: [],
    board: "",
  };
  const [filters, setFilters] = useState<any>(initialFilters);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const handleFilterChange = (filter: {
    name: string;
    label: string;
    board: string;
  }) => {
    let updatedActiveFilters = [...activeFilters];

    if (updatedActiveFilters.includes(filter.name)) {
      updatedActiveFilters = updatedActiveFilters.filter(
        (filterName) => filterName !== filter.name
      );
    } else {
      updatedActiveFilters.push(filter.name);
    }

    setActiveFilters(updatedActiveFilters);

    if (filter.board !== "") {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        board: filter.board,
      }));
    } else {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        stars: updatedActiveFilters,
      }));
    }
  };

  const updateFilters = (newFilters: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const clearFilters = () => {
    setFilters(initialFilters);
    setActiveFilters([]);
  };
  const mockStars = [
    { name: "5", label: "★★★★★" },
    { name: "4", label: "★★★★" },
    { name: "3", label: "★★★" },
    { name: "2", label: "★★" },
  ];
  const mockFoodData = [
    { name: "Ultra kõik hinnas", label: "UAI" },
    { name: "Kõik hinnas", label: "AI" },
    { name: "Hommiku- ja õhtusöögiga", label: "HB" },
    { name: "Hommikusöögiga", label: "BB" },
    { name: "Ainult tuba", label: "RO" },
  ];
  const convertFiltersToLabels = (filters: string[]) => {
    const labels: string[] = [];
    filters.forEach((filter) => {
      const selectedStarLabel = mockStars.find(
        (star) => star.name === filter
      )?.label;
      const selectedFoodLabel = mockFoodData.find(
        (food) => food.name === filter
      )?.label;
      if (selectedStarLabel) {
        labels.push(selectedStarLabel);
      }
      if (selectedFoodLabel) {
        labels.push(selectedFoodLabel);
      }
    });
    return labels;
  };

  const removeFilter = (filterToRemove: string) => {
    const updatedFilters = activeFilters.filter(
      (filter) => filter !== filterToRemove
    );
    setActiveFilters(updatedFilters);

    const labels = convertFiltersToLabels([filterToRemove]);

    labels.forEach((label) => {
      const updatedStars = filters.stars.filter(
        (star: string) => star !== label
      );
      const updatedBoard = filters.board.filter(
        (board: string) => board !== label
      );

      setFilters({
        ...filters,
        stars: updatedStars,
        board: updatedBoard,
      });
    });
  };

  return (
    <FilterContext.Provider
      value={{
        filters,
        updateFilters,
        clearFilters,
        removeFilter,
        activeFilters,
        mockStars,
        handleFilterChange,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);
