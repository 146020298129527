import React, { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import left from "../../assets/icons/sliderLeftArrow.svg";
import right from "../../assets/icons/sliderRightArrow.svg";
import loc from "../../assets/icons/locationP.svg";
import close from "../../assets/icons/closeP.svg";
import hotel from "../../assets/icons/hotel.svg";
import star from "../../assets/icons/star.svg";
import food from "../../assets/icons/food.svg";
import children from "../../assets/icons/children.svg";
import bus from "../../assets/icons/bus.svg";
import beach from "../../assets/icons/beach.svg";
import ResultsBooking from "./ResultsBooking";
import room from "../../assets/icons/room.svg";

function PrevArrow(props: any) {
  const { onClick } = props;
  return (
    <div className="prev-arrow" onClick={onClick}>
      <img src={left} />
    </div>
  );
}
function NextArrow(props: any) {
  const { onClick } = props;
  return (
    <div className="next-arrow" onClick={onClick}>
      <img src={right} />
    </div>
  );
}
const ResultsSingleViewSm: FC = (): JSX.Element => {
  const location = useLocation();
  const { hotelCode } = useParams<{ hotelCode: string }>();
  const [hotelData, setHotelData] = useState<any>(null);
  const [hotelPhotos, setHotelPhotos] = useState<string[]>([]);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const handleSlideChange = (index: number) => {
    setCurrentSlide(index);
  };
  const settings = {
    dots: true,
    dotsClass: "slick-dots custom-dots",
    infinite: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const fetchHotelData = async () => {
      try {
        const response = await axios.get(
          `/api/hotels/hotel/${hotelCode}?${searchParams}`
        );
        setHotelData(response.data);

        const photosResponse = await axios.get(
          `/api/hotels/photos/${hotelCode}`
        );
        const processedPhotos = photosResponse.data.map(
          (photo: any) => `https://reisihunt.ee${photo.url}`
        );

        setHotelPhotos(processedPhotos);
      } catch (error) {
        console.error("Error fetching hotel data:", error);
      }
    };

    fetchHotelData();
  }, [hotelCode, location.search]);
  const renderStars = (stars: number) => {
    const starIcons = [];
    for (let i = 0; i < stars; i++) {
      starIcons.push(<img key={i} src={star} alt={`Star ${i + 1}`} />);
    }
    return starIcons;
  };
  return (
    <div className="single-view-sm">
      <div
        className="single-view-sm__carousel"
        style={{ position: "relative" }}
      >
        <Slider {...settings} afterChange={handleSlideChange}>
          {hotelPhotos.map((photo: string, index: number) => (
            <div key={index}>
              <img src={photo} alt={`Photo ${index + 1}`} />
            </div>
          ))}
        </Slider>
        <div className="single-view-sm__picture">{`${currentSlide + 1} / ${
          hotelPhotos.length
        }`}</div>
      </div>
      <div className="single-view-sm__wrapper">
        {hotelData ? (
          <>
            <div className="single-view-sm__header">
              <h1>{hotelData.name}</h1>
              {/* <p>Asukoht</p> */}
              <div className="single-view-sm__perks">
                {renderStars(hotelData.stars)}
                {/* <ul>
                  <li>
                    <img src={food} alt="" /> Kõik hinnas
                  </li>
                  <li>
                    <img src={children} alt="" /> Lastesõbralik
                  </li>
                  <li>
                    <img src={bus} alt="" /> Lennujaama transfeer
                  </li>
                  <li>
                    <img src={beach} alt="" /> Ranna lähedal
                  </li>
                </ul> */}
              </div>
            </div>
            {hotelData.descriptions.some(
              (description: any) => description.title === "asukoht"
            ) && (
              <div className="single-view__location">
                <div className="single-view__location--title">
                  <img src={loc} alt="" />
                  <h2>Asukoht</h2>
                </div>
                <div className="single-view__location--list">
                  <ul>
                    {Array.from(
                      new Set(
                        hotelData.descriptions
                          .filter(
                            (description: any) =>
                              description.title === "asukoht"
                          )
                          .map((description: any) => description.data)
                      )
                    ).map((data: any) => (
                      <li>{data}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div className="single-view__location">
              <div className="single-view__location--title">
                <img src={hotel} alt="" />
                <h2>Hotell</h2>
              </div>
              <div>
                <ul>
                  {Array.from(
                    new Set(
                      hotelData.descriptions
                        .filter(
                          (description: any) =>
                            description.title === "hotell" ||
                            description.title === "basseinid"
                        )
                        .slice(0, 6)
                        .map((description: any) => description.data)
                    )
                  ).map((data: any) => (
                    <li>{data}</li>
                  ))}

                  {Array.from(
                    new Set(
                      hotelData.descriptions
                        .filter(
                          (description: any) =>
                            description.title === "hotell" ||
                            description.title === "basseinid"
                        )
                        .slice(6)
                        .map((description: any) => description.data)
                    )
                  ).map((data: any) => (
                    <li>{data}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="single-view__location">
              <div
                className="single-view__location--title"
                style={{
                  display: hotelData.descriptions.filter(
                    (description: any) => description.title === "lastele"
                  ).length
                    ? "block"
                    : "none",
                }}
              >
                <img src={hotel} alt="" />
                <h2>Lastele</h2>
              </div>
              <div className="single-view__location--list">
                <ul>
                  {hotelData.descriptions
                    .filter(
                      (description: any) => description.title === "lastele"
                    )
                    .slice(0, 5)
                    .map((description: any) => (
                      <li>{description.data}</li>
                    ))}

                  {hotelData.descriptions
                    .filter(
                      (description: any) => description.title === "lastele"
                    )
                    .slice(5)
                    .map((description: any) => (
                      <li>{description.data}</li>
                    ))}
                </ul>
              </div>
            </div>
            <div
              className="single-view__location"
              style={{
                display: hotelData.descriptions.some(
                  (description: any) => description.title === "toad"
                )
                  ? "block"
                  : "none",
              }}
            >
              <div className="single-view__location--title">
                <img src={room} alt="" />
                <h2>Toad</h2>
              </div>
              <div>
                <div className="room">
                  <ul>
                    {hotelData.descriptions
                      .filter(
                        (description: any) => description.title === "toad"
                      )
                      .map((description: any, index: number) => {
                        try {
                          const parsedData = JSON.parse(description.data);
                          const { kirjeldus } = parsedData;
                          if (
                            Array.isArray(kirjeldus) &&
                            kirjeldus.length > 1
                          ) {
                            kirjeldus.shift();
                          }
                          return (
                            <div key={index} className="room-item">
                              <strong>{parsedData.toatyyp}</strong>
                              <div className="room-item__grid">
                                <ul>
                                  {kirjeldus
                                    .slice(0, 6)
                                    .map((description: string, i: number) => (
                                      <li key={i}>{description}</li>
                                    ))}
                                </ul>
                                <ul>
                                  {kirjeldus
                                    .slice(6)
                                    .map((description: string, i: number) => (
                                      <li key={i}>{description}</li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          );
                        } catch (error) {
                          console.error(
                            `Error parsing description at index ${index}:`,
                            error
                          );
                          return null;
                        }
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <ResultsBooking />
          </>
        ) : (
          "loading"
        )}
      </div>
    </div>
  );
};

export default ResultsSingleViewSm;
