import React, { FC } from "react";
import alert from "../../../assets/icons/alert.svg";
import { Link } from "react-router-dom";

const HeroAlert: FC = (): JSX.Element => {
  return (
    <div className="hero-alert">
      <img src={alert} alt="" />
      <Link target="_blank" to="https://paketikas.ee">
        <h6>Broneeri online</h6>
      </Link>
    </div>
  );
};

export default HeroAlert;
