import React, { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";

import loc from "../../assets/icons/locationP.svg";
import close from "../../assets/icons/closeP.svg";
import hotel from "../../assets/icons/hotel.svg";
import star from "../../assets/icons/star.svg";
import food from "../../assets/icons/food.svg";
import children from "../../assets/icons/children.svg";
import bus from "../../assets/icons/bus.svg";
import beach from "../../assets/icons/beach.svg";
import room from "../../assets/icons/room.svg";
import arrow from "../../assets/icons/purp.svg";
import arrowW from "../../assets/icons/arrowR.svg";

import axios from "axios";

import left from "../../assets/icons/sliderLeftArrow.svg";
import right from "../../assets/icons/sliderRightArrow.svg";
import { Box, Modal, Typography } from "@mui/material";
import ResultsBooking from "./ResultsBooking";
import ResultsSingleViewSm from "./ResultsSingleViewSm";

function PrevArrow(props: any) {
  const { onClick } = props;
  return (
    <button className="prev-arrow" onClick={onClick}>
      <img src={left} />
    </button>
  );
}
function NextArrow(props: any) {
  const { onClick } = props;
  return (
    <button className="next-arrow" onClick={onClick}>
      <img src={right} />
    </button>
  );
}
type BoardType = "AI" | "BB" | "RO" | "HB" | "UAI";
const boardMeanings: Record<BoardType, string> = {
  AI: "Kõik hinnas",
  BB: "Hommikusöögiga",
  RO: "Ainult tuba",
  HB: "Hommiku- ja õhtusöögiga",
  UAI: "Ultra kõik hinnas",
};

const ResultsSingleView: FC = (): JSX.Element => {
  const location = useLocation();
  const { hotelCode } = useParams<{ hotelCode: string }>();
  const [hotelData, setHotelData] = useState<any>(null);
  const [hotelPhotos, setHotelPhotos] = useState<string[]>([]);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const settings = {
    dots: true,
    dotsClass: "slick-dots custom-dots",
    infinite: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const fetchHotelData = async () => {
      try {
        const response = await axios.get(
          `/api/hotels/hotel/${hotelCode}`
        );
        setHotelData(response.data);

        const photosResponse = await axios.get(
          `/api/hotels/photos/${hotelCode}`
        );
        const processedPhotos = photosResponse.data.map(
          (photo: any) => `https://reisihunt.ee${photo.url}`
        );

        setHotelPhotos(processedPhotos);
      } catch (error) {
        console.error("Error fetching hotel data:", error);
      }
    };

    fetchHotelData();
  }, [hotelCode, location.search]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const closeModal = () => {
    setShowModal(false);
  };
  const renderStars = (stars: number) => {
    const starIcons = [];
    for (let i = 0; i < stars; i++) {
      starIcons.push(<img key={i} src={star} alt={`Star ${i + 1}`} />);
    }
    return starIcons;
  };
  const [previewPhotos, setPreviewPhotos] = useState<string[]>(
    hotelPhotos.slice(1, 4)
  );
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

  useEffect(() => {
    setPreviewPhotos(
      hotelPhotos.slice(selectedPhotoIndex + 1, selectedPhotoIndex + 4)
    );
  }, [selectedPhotoIndex, hotelPhotos]);

  const handlePreviewClick = (index: number) => {
    setSelectedPhotoIndex(index);
  };

  // const boardMeaning = (hotelData.board && boardMeanings) || "";
  // const showBoardMeaning = hotelData.board !== "NA";

  return (
    <>
      {isMobile ? (
        <ResultsSingleViewSm />
      ) : (
        <div className="single-view">
          {hotelData ? (
            <div className="single-view__grid">
              <div className="single-view__header">
                {/* <h3>Asukoht</h3> */}
                <h1>{hotelData.name}</h1>
                <div className="single-view__perks">
                  {renderStars(hotelData.stars)}
                  <ul>
                    {/* {showBoardMeaning && (
                      <li>
                        <img src={food} alt="" />
                        {boardMeaning}
                      </li>
                    )} */}
                  </ul>
                </div>
              </div>
              <div className="single-view__wrapper">
                <div className="single-view__slider">
                  <Slider
                    {...settings}
                    initialSlide={selectedPhotoIndex}
                    afterChange={setSelectedPhotoIndex}
                  >
                    {hotelPhotos
                      .slice(0, 7)
                      .map((photo: string, index: number) => (
                        <div key={index}>
                          <img src={photo} alt={`Slider Photo ${index + 1}`} />
                        </div>
                      ))}
                  </Slider>
                </div>
                {hotelData.descriptions.some(
                  (description: any) => description.title === "asukoht"
                ) && (
                  <div className="single-view__location">
                    <div className="single-view__location--title">
                      <img src={loc} alt="" />
                      <h2>Asukoht</h2>
                    </div>
                    <div className="single-view__location--list">
                      <ul>
                        {Array.from(
                          new Set(
                            hotelData.descriptions
                              .filter(
                                (description: any) =>
                                  description.title === "asukoht"
                              )
                              .map((description: any) => description.data)
                          )
                        ).map((data: any) => (
                          <li>{data}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                <div className="single-view__location">
                  <div className="single-view__location--title">
                    <img src={hotel} alt="" />
                    <h2>Hotell</h2>
                  </div>
                  <div className="single-view__location--list">
                    <ul>
                      {Array.from(
                        new Set(
                          hotelData.descriptions
                            .filter(
                              (description: any) =>
                                description.title === "hotell" ||
                                description.title === "basseinid"
                            )
                            .slice(0, 10)
                            .map((description: any) => description.data)
                        )
                      ).map((data: any) => (
                        <li>{data}</li>
                      ))}
                    </ul>
                    <ul>
                      {Array.from(
                        new Set(
                          hotelData.descriptions
                            .filter(
                              (description: any) =>
                                description.title === "hotell" ||
                                description.title === "basseinid"
                            )
                            .slice(10)
                            .map((description: any) => description.data)
                        )
                      ).map((data: any) => (
                        <li>{data}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div
                  className="single-view__location"
                  style={{
                    display: hotelData.descriptions.filter(
                      (description: any) => description.title === "lastele"
                    ).length
                      ? "block"
                      : "none",
                  }}
                >
                  <div className="single-view__location--title">
                    <img src={hotel} alt="" />
                    <h2>Lastele</h2>
                  </div>
                  <div className="single-view__location--list">
                    <ul>
                      {hotelData.descriptions
                        .filter(
                          (description: any) => description.title === "lastele"
                        )
                        .slice(0, 8)
                        .map((description: any) => (
                          <li>{description.data}</li>
                        ))}
                    </ul>
                    <ul>
                      {hotelData.descriptions
                        .filter(
                          (description: any) => description.title === "lastele"
                        )
                        .slice(8)
                        .map((description: any) => (
                          <li>{description.data}</li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div
                  className="single-view__location"
                  style={{
                    display: hotelData.descriptions.some(
                      (description: any) => description.title === "toad"
                    )
                      ? "block"
                      : "none",
                  }}
                >
                  <div className="single-view__location--title">
                    <img src={room} alt="" />
                    <h2>Toad</h2>
                  </div>
                  <div>
                    <div className="room">
                      <ul>
                        {hotelData.descriptions
                          .filter(
                            (description: any) => description.title === "toad"
                          )
                          .map((description: any, index: number) => {
                            try {
                              const parsedData = JSON.parse(description.data);
                              const { kirjeldus } = parsedData;
                              if (
                                Array.isArray(kirjeldus) &&
                                kirjeldus.length > 1
                              ) {
                                kirjeldus.shift();
                              }
                              return (
                                <div key={index} className="room-item">
                                  <strong>{parsedData.toatyyp}</strong>
                                  <div className="room-item__grid">
                                    <ul>
                                      {kirjeldus
                                        .slice(0, 8)
                                        .map(
                                          (description: string, i: number) => (
                                            <li key={i}>{description}</li>
                                          )
                                        )}
                                    </ul>
                                    <ul>
                                      {kirjeldus
                                        .slice(8)
                                        .map(
                                          (description: string, i: number) => (
                                            <li key={i}>{description}</li>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              );
                            } catch (error) {
                              console.error(
                                `Error parsing description at index ${index}:`,
                                error
                              );
                              return null;
                            }
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-view__wrapper-right">
                <div className="single-view__gallery">
                  <div className="single-view__gallery--preview">
                    {hotelPhotos.length > 0 &&
                      Array.from({ length: 2 }).map((_, i) => {
                        const photoIndex =
                          (selectedPhotoIndex + i + 1) % hotelPhotos.length;
                        const photo = hotelPhotos[photoIndex];
                        return (
                          <div key={photoIndex}>
                            <img src={photo} alt="photo" />
                          </div>
                        );
                      })}
                  </div>

                  <div className="single-view__gallery--button">
                    <button
                      className="modal-button"
                      onClick={openModal}
                      style={{
                        alignItems: "center",
                        backgroundColor: showModal
                          ? "var(--clr-primary)"
                          : "#FFFFFF",
                        color: showModal ? "#FFFFFF" : "var(--clr-primary)",
                      }}
                    >
                      Rohkem pilte {""} ({hotelPhotos.length} tk)
                      {showModal ? (
                        <img src={arrowW} alt="" />
                      ) : (
                        <img src={arrow} alt="" />
                      )}
                    </button>
                  </div>
                </div>
                <Modal
                  open={showModal}
                  onClose={closeModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      p: 4,
                      padding: "0 0.75rem 0.75rem 0.75rem",
                      maxHeight: "90vh",
                      overflowY: "auto",
                      maxWidth: "838px",
                      display: "flex",
                      flexDirection: "column",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      borderRadius: "12px",
                    }}
                  >
                    <Box
                      sx={{
                        position: "sticky",
                        top: "0",
                        height: "48px",
                        padding: "12px 0 12px 0",
                        width: "100%",
                        justifyContent: "space-between",
                        backgroundColor: "#fff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: ".25rem",
                          }}
                        >
                          <Typography
                            variant="h3"
                            component="div"
                            sx={{
                              fontSize: "20px",
                              color: "var(--font-clr-secondary)",
                              fontWeight: "400",
                            }}
                          >
                            {hotelData.name},
                          </Typography>
                          {/* <Typography
                            variant="h3"
                            component="div"
                            sx={{
                              fontSize: "20px",
                              color: "#645F6C",
                              fontWeight: "400",
                            }}
                          >
                            Asukoht
                          </Typography> */}
                        </div>

                        <p
                          onClick={closeModal}
                          style={{
                            display: " flex",
                            alignItems: " center",
                            gap: "0.25rem",
                            color: "var(--clr-primary)",
                            fontSize: "1rem",
                            fontWeight: "700",
                            cursor: "pointer",
                          }}
                        >
                          <img src={close} alt="" />
                          Sulge
                        </p>
                      </div>
                    </Box>
                    <div className="modal-images">
                      {hotelPhotos.map((photo: string, index: number) => (
                        <img
                          key={index}
                          src={photo}
                          alt={`Modal ${index + 1}`}
                          style={{ borderRadius: "12px", marginBottom: "1rem" }}
                        />
                      ))}
                    </div>
                  </Box>
                </Modal>
                <ResultsBooking />
              </div>
            </div>
          ) : (
            <p style={{ paddingTop: "4.5rem" }}>Loading...</p>
          )}
        </div>
      )}
    </>
  );
};

export default ResultsSingleView;
